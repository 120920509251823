import React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Box, Breadcrumbs, Button, Link, Stack, Typography } from "@mui/material";
import { useApiGet } from "../../../api/ApiHooks";
import { Apis } from "../../../api/Config";
import { Trip } from "../../../api/models/Vivi";
import TripForm from "../components/TripForm";
import BorderedSection from "../../../atoms/BorderedSection";
import ApizedAudit from "../../../components/audit/ApizedAudit";
import { Service } from "../../../api/Api";

const TripPage = () => {
  const { id } = useParams<any>();
  const navigate = useNavigate();

  const { data: trip, loading } = useApiGet<Trip>(
    Apis.Vivi.Trip,
    { token: '8fb4fa31-1a78-4d25-82dc-a9dda48fa7d8' },
    {
      id,
    }
  )

  return (
    <Stack spacing={"1em"}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="#/vivi/trips">
            Trips
          </Link>
          <Typography color="text.primary">Trip <b>{trip?.id}</b></Typography>
        </Breadcrumbs>
        <Stack direction={"row"} spacing={"1em"}>
          <Button variant={"contained"} onClick={() => navigate(`/vivi/profiles/${trip.owner}`)}>Profile</Button>
          <ApizedAudit service={Service.Vivi} entity={"trips"} target={trip?.id}/>
        </Stack>
      </Stack>
      {!loading && trip && (
        <>
          <BorderedSection title={"Details"}>
            <TripForm selected={trip}/>
            <Box>Confidence: {trip.confidence}</Box>
          </BorderedSection>
        </>
      )}
    </Stack>
  );
};

export default TripPage;
