import React from 'react';

const Logo = () => {
  return (
    <svg style={{width: '48px',height: '48px', background: 'yellow', borderRadius:"1em"}} version="1.0" xmlns="http://www.w3.org/2000/svg" width="160px" height="160px" viewBox="0 0 1024.000000 1024.000000" preserveAspectRatio="xMidYMid meet">
      <g transform="translate(0.000000,1024.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
        <path
          d="M2970 7800 c-292 -41 -590 -224 -751 -461 -56 -83 -115 -209 -141 -304 -18 -68 -22 -106 -22 -245 0 -150 3 -173 28 -255 14 -49 44 -127 66 -172 42 -89 2362 -4123 2437 -4238 56 -85 187 -222 268 -280 292 -208 669 -236 1012 -75 227 106 435 316 525 530 111 267 100 569 -32 831 -58 114 -2412 4194 -2455 4254 -50 70 -189 207 -258 254 -189 129 -455 192 -677 161z"/>
        <path
          d="M6810 7804 c-183 -21 -322 -60 -463 -130 -138 -68 -223 -130 -337 -244 -192 -192 -304 -403 -357 -668 -23 -119 -23 -356 1 -477 80 -418 281 -798 908 -1719 227 -333 249 -356 348 -356 63 0 118 27 157 77 51 65 445 649 565 838 429 673 573 1056 555 1469 -25 576 -449 1070 -1016 1186 -95 19 -290 32 -361 24z"/>
      </g>
    </svg>
  );
};

export default Logo;
