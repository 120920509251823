import { Model, UUID } from "./Base";

export enum TravelMode {
  DEPART = 'DEPART',
  ARRIVE = 'ARRIVE'
}

export enum Source {
  USER = 'USER',
  SCHEDULE = 'SCHEDULE',
  AI = 'AI'
}

export enum Gateway {
  STRIPE = 'STRIPE',
  BRAINTREE = 'BRAINTREE',
}

export enum OrderStatus {
  PENDING = 'PENDING',
  COMPLETE = 'COMPLETE',
  CANCELLED = 'CANCELLED'
}

export enum AlertType {
  WARNING = 'WARNING',
  DOWNTIME = 'DOWNTIME',
}

export enum Delivery {
  ETK = 'ETK',
  TOD = 'TOD',
}

export enum VersionType {
  IOS = 'IOS',
  ANDROID = 'ANDROID',
  ToS = 'ToS',
}

export enum TravelDirection {
  OUTBOUND = 'OUTBOUND',
  INBOUND = 'INBOUND',
}

export enum Frequency {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
}

export enum LocationType {
  GEO_CODE = 'GEO_CODE',
  STOP_CODE = 'STOP_CODE',
  STOP_UID = 'STOP_UID',
  UNKNOW = 'UNKNOW'
}

export type Info = {
  needsToSAcceptance?: boolean
  alerts?: (Alert | string)[]
  versions?: (Version | string)[]
  suggestionThreshold?: number
} & Model;

export type Location = {
  name?: string
  address?: string
  value?: string
} & Model

export type Favourite = {
  name?: string
  address?: string
  type?: LocationType
  value?: string
} & Model;

export type Profile = {
  name?: string
  email?: string
  acceptedTos?: string
  processingAllowed?: boolean
  commute?: Route
  paymentMethods?: (string | PaymentMethod)[]
  defaultPaymentMethod?: PaymentMethod
  railCards?: (string | RailCard)[]
  featureFlags?: { [key: string]: unknown }
} & Model

export type Journey = {
  owner?: string
  from?: Location
  to?: Location
  mode?: TravelMode
  date?: string
  source: Source
} & Model

export type Order = {
  completedAt?: string
  billed?: number
  paid?: number
  total?: number
  trip: string | Trip
  paymentMethod?: string | PaymentMethod
  gateway?: Gateway
  owner?: string
  reference?: string
  externalSystem?: string
  externalReference?: string
  currency?: string
  status?: OrderStatus
  customer: string | Customer
  orderItems?: (OrderItem | string)[]
  transactions?: (string | Transaction)[]
} & Model

export type OrderItem = {
  order?: Order | string
  externalReference?: string
  fare?: Fare | string
  tickets?: (Ticket | string)[]
} & Model

export type Alert = {
  message?: string
  start?: string,
  effectStart?: string,
  end?: string,
  type?: AlertType
} & Model

export type Trip = {
  journeyConfidence?: number
  journey?: Journey
  orders?: (string | Order)[]
  legs?: (string | Leg)[]
  fares?: (string | Fare)[]
  owner?: string
  confidence?: number
  depart?: string
  arrive?: string
  duration?: number
  changes?: number
  follow?: boolean
  overtaken?: boolean
  from?: string | Location
  to?: string | Location
  mode?: TravelMode
  date?: string
  cheapestFare?: string | Fare
  source: Source
} & Model

export type Ticket = {
  orderItem?: OrderItem | string
  legs?: (Leg | string)[]
  reference?: string
  mimeType?: string
  binary?: string
  coach?: string
  number?: string
  seatAttributes?: string[]
  owner?: string
} & Model

export type Leg = {} & Model

export type Fare = {
  name?: string
  from: string | Location
  to: string | Location
  scheduledDepart?: string
  scheduledArrive?: string
  validFrom?: string
  validUntil?: string
  legs?: (string | Leg)[]
  cabinClass: string
  price: number
  currency: string
  roundTrip: boolean
  fulfillmentOptions?: Delivery[]
  fareRules?: string | FareRules
  duration: number
  changes: number
} & Model

export type FareRules = {
  ticketing?: string[]
  breakOfJourney?: string[]
  routing: string
  afterSales: { [key: string]: AfterSales };
} & Model

export type AfterSales = {
  refund?: string
  exchange?: string
} & Model

export type Schedule = {
  owner?: UUID
  direction?: TravelDirection
  mode?: TravelMode
  frequency?: Frequency
  start: string
  end: string;
  cadence?: number
  values?: number[]
  time?: string
} & Model

export type Route = {
  owner?: UUID
  from?: Location
  to?: Location
  schedules?: (UUID | Schedule)[]
  deleted?: boolean
} & Model

export type PaymentMethod = {} & Model

export type RailCard = {} & Model
export type Customer = {} & Model
export type Transaction = {} & Model
export type Version = {
  type?: VersionType
  semVer?: string
} & Model

export type FeatureFlag = {
  name?: string
  description?: string
  active?: boolean
} & Model