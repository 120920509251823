import { Button, Stack } from "@mui/material";
import React from "react";
import { Alert, AlertType } from "../../../api/models/Vivi";
import { ApizedFormProps } from "../../../components/ApizedListPage";
import { useSnackbar } from "notistack";
import apiFor, { ApiError } from "../../../api/Api";
import { Apis } from "../../../api/Config";
import { useFormik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import { convertTimeZone, toISOString } from "../../../lib/DateTime";
import FormikField from "../../../components/FormikField";

const AlertForm = (
  {
    isModal,
    onClose = (Alert) => null,
    selected
  }: ApizedFormProps<Alert>
) => {
  const { enqueueSnackbar } = useSnackbar();
  const api = apiFor(Apis.Vivi.Alert, { token: '8fb4fa31-1a78-4d25-82dc-a9dda48fa7d8' });

  const close = (u?: Alert) => {
    formik.resetForm();
    onClose(u);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: selected?.id || undefined,
      message: selected?.message || '',
      type: selected?.type || '' as AlertType,
      start: selected?.start ? dayjs(
        convertTimeZone({ date: new Date(selected?.start), from: "UTC" })
      ).toISOString() : undefined,
      effectStart: selected?.effectStart ? dayjs(
        convertTimeZone({ date: new Date(selected?.effectStart), from: "UTC" })
      ).toISOString() : undefined,
      end: selected?.end ? dayjs(
        convertTimeZone({ date: new Date(selected?.end), from: "UTC" })
      ).toISOString() : undefined,
    },
    validationSchema: Yup.object().shape({
      message: Yup.string().required(),
      type: Yup.string().required(),
      start: Yup.string().required(),
      effectStart: Yup.string().required(),
      end: Yup.string().required(),
    }),
    onSubmit: (values) => {
      if (selected) {
        api.update({
          id: selected.id!,
          obj: {
            ...values,
          start: toISOString(convertTimeZone({ date: new Date(values.start!), to: 'UTC' })),
          effectStart: toISOString(convertTimeZone({ date: new Date(values.effectStart!), to: 'UTC' })),
          end: toISOString(convertTimeZone({ date: new Date(values.end!), to: 'UTC' }))
          },
        }).then((u) => {
          close(u);
          enqueueSnackbar(`Alert '${u.id}' updated!`, { variant: "success" });
        }).catch((e: ApiError) => {
          e.errors.map(error => enqueueSnackbar(error.message, { variant: "error" }))
        })
      } else {
        api.create({
          obj: values,
        }).then((u) => {
          close(u);
          enqueueSnackbar(`Alert '${u.id}' created!`, { variant: "success" });
        }).catch((e: ApiError) => {
          e.errors.map(error => enqueueSnackbar(error.message, { variant: "error" }))
        })
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={"1em"} bottom={"1em"}>
        <FormikField
          formik={formik}
          label="Message"
          field="message"
          type="text"
        />
        <FormikField
          formik={formik}
          label={"Type"}
          field={"type"}
          type={"select"}
          options={Object.keys(AlertType).map((p) => (
            { label: p, value: p }
          ))}
        />
        <FormikField
          label={"Start"}
          field={"start"}
          type={"datetime"}
          formik={formik}
        />
        <FormikField
          label={"Effect Start"}
          field={"effectStart"}
          type={"datetime"}
          formik={formik}
        />
        <FormikField
          formik={formik}
          label={"End"}
          field={"end"}
          type={"datetime"}
        />
        <Stack direction={"row"} spacing={"1em"} justifyContent={"right"}>
          {isModal && (<Button variant={"outlined"} onClick={() => close()}>Cancel</Button>)}
          <Button variant={"contained"} onClick={formik.submitForm}>{selected ? 'Update' : 'Create'}</Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default AlertForm;