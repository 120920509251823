import React from 'react';
import { Breadcrumbs, Stack, Typography } from "@mui/material";
import { SearchEntry } from "../../../components/search/Types";
import { Operation } from "../../../api/Search";
import { Apis } from "../../../api/Config";
import { Version, VersionType } from "../../../api/models/Vivi";
import ApizedListPage from "../../../components/ApizedListPage";
import VersionForm from "../components/VersionForm";

const VersionsPage = () => {
  const searchConfig: SearchEntry[] = [
    {
      id: 'type',
      label: "Type",
      operations: [
        { label: "is", value: Operation.Equals },
        { label: "is not", value: Operation.NotEquals },
      ],
      value: 'id',
      values: Object.keys(VersionType).map((o) => ({ label: o, value: o }))
    },
  ];

  return (
    <Stack spacing={"1em"}>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary">Versions</Typography>
      </Breadcrumbs>
      <ApizedListPage<Version>
        columns={[
          { label: 'Type', minWidth: 170, format: (value) => value.type },
          { label: 'Version', minWidth: 170, format: (value) => value.semVer },
        ]}
        fields={[ '*', 'from.*', 'to.*' ]}
        form={VersionForm}
        context={{ token: '8fb4fa31-1a78-4d25-82dc-a9dda48fa7d8' }}
        query={Apis.Vivi.Version}
        searchConfig={searchConfig}
        disableCreatedAt
      />
    </Stack>
  );
};

export default VersionsPage;
