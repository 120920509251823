import { Model } from "./Base";

export enum MessageType {
  RAW = 'RAW',
  TEMPLATE = 'TEMPLATE'
}

export enum MessageStatus {
  ERROR = 'ERROR',
  PENDING = 'PENDING',
  SENT = 'SENT',
  DELIVERED = 'DELIVERED',
  BOUNCED = 'BOUNCED',
  OPENED = 'OPENED',
  DELAYED = 'DELAYED',
  REJECTED = 'REJECTED',
}

export type Attachment = {
  filename?: string
  mimeType?: string
  data?: string
} & Model

export type Message = {
  createdAt?: string
  address?: string
  type?: MessageType
  subject?: string
  body?: string
  status?: MessageStatus
  template?: string | Template
  variables?: { [key: string]: unknown }
  attachments?: (string | Attachment)[]
} & Model

export type Collection = {
  name?: string
  sender?: string
  variables?: { [key: string]: unknown }
} & Model

export type Template = {
  name?: string
  subject?: string
  body?: string
} & Model