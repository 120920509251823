import React from 'react';
import { Stack } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { SearchEntry } from "../../../components/search/Types";
import { Operation } from "../../../api/Search";
import { Apis } from "../../../api/Config";
import { Message, MessageStatus, Template } from "../../../api/models/Email";
import ApizedListPage from "../../../components/ApizedListPage";
import MessageForm from "../components/MessageForm";

const MessagesPage = () => {
  const navigate = useNavigate();

  const { collection } = useParams<any>();

  const searchConfig: SearchEntry[] = [
    {
      id: 'address',
      label: "Address",
      operations: [
        { label: "contains", value: Operation.Like },
        { label: "is", value: Operation.Equals },
        { label: "is not", value: Operation.NotEquals },
      ],
      value: 'address',
      values: {
        label: 'address',
        value: 'address',
        query: {
          context: { collection },
          definition: Apis.Email.Message
        },
      }
    },
    {
      id: 'subject',
      label: "Subject",
      operations: [
        { label: "contains", value: Operation.Like },
        { label: "is", value: Operation.Equals },
        { label: "is not", value: Operation.NotEquals },
      ],
      value: 'subject',
      values: {
        label: 'subject',
        value: 'subject',
        query: {
          context: { collection },
          definition: Apis.Email.Message
        },
        create: true
      }
    },
    {
      id: 'status',
      label: "Status",
      value: "status",
      operations: [
        { label: "is", value: Operation.Equals },
        { label: "is not", value: Operation.NotEquals },
      ],
      values: Object.keys(MessageStatus).map((op) => ({ label: op, value: op }))
    }
  ];

  return (
    <Stack spacing={"1em"}>
      <ApizedListPage<Message>
        columns={[
          { label: 'To', minWidth: 170, format: (value) => value.address },
          { label: 'Template', minWidth: 170, format: (value) => (value.template as Template)?.name || 'N/A' },
          { label: 'Status', minWidth: 170, format: (value) => value.status },
        ]}
        form={MessageForm}
        fields={[ '*', 'template.name' ]}
        context={{ collection }}
        query={Apis.Email.Message}
        onRowClick={(message) => navigate(`/email/collections/${collection}/messages/${message.id}`)}
        searchConfig={searchConfig}
      />
    </Stack>
  );
};

export default MessagesPage;
