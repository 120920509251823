import { Apis } from "../../../api/Config";
import React from "react";
import { Operation } from "../../../api/Search";
import { Button, Stack } from "@mui/material";
import ApizedListPage from "../../../components/ApizedListPage";
import { Execution, Message, Target } from "../../../api/models/Webhooks";
import { GreenBadge, GreyBadge, RedBadge, YellowBadge } from "../../../atoms/Badge";
import { useNavigate } from "react-router-dom";
import apiFor from "../../../api/Api";
import { SearchEntry } from "../../../components/search/Types";

const ExecutionsPage = () => {
  const navigate = useNavigate();
  const executionApi = apiFor(Apis.Webhooks.Execution);
  const searchConfig: SearchEntry[] = [
    {
      id: 'status',
      label: 'Status',
      operations: [
        { label: 'is', value: Operation.Equals },
        { label: 'is not', value: Operation.Like },
      ],
      value: 'status',
      values: [
        { label: '200', value: '200' }
      ],
    },
    {
      id: 'topic',
      label: 'Topic',
      operations: [
        { label: 'is', value: Operation.Equals },
        { label: 'contains', value: Operation.Like },
      ],
      value: 'message.topic',
      values: {
        create: true,
        label: 'topic',
        value: 'topic',
        query: {
          context: {},
          definition: Apis.Webhooks.Message,
        },
      },
    },
    {
      id: 'target',
      label: 'Target',
      operations: [
        { label: 'is', value: Operation.Equals },
        { label: 'is not', value: Operation.NotEquals },
      ],
      value: 'target.id',
      values: {
        label: 'name',
        query: {
          context: {},
          definition: Apis.Webhooks.Target,
        }
      },
    },
  ];

  return (
    <Stack spacing={"1em"}>
      <ApizedListPage<Execution>
        columns={[
          {
            label: 'Message',
            minWidth: 250,
            format: (item) => (item.message as Message)?.topic,
          },
          {
            label: 'Target',
            minWidth: 350,
            format: (item) => (item.target as Target)?.name,
          },
          {
            label: 'Status',
            minWidth: 100,
            format: (item) => {
              switch (Math.round((item.latestStatus || 1) / 100)) {
                case 2:
                  return <GreenBadge>{item.latestStatus}</GreenBadge>;
                case 4:
                  return <YellowBadge>{item.latestStatus}</YellowBadge>;
                case 5:
                  return <RedBadge>{item.latestStatus}</RedBadge>;
                default:
                  return <GreyBadge>{item.latestStatus}</GreyBadge>;
              }
            },
          },
          {
            label: 'Actions',
            minWidth: 100,
            format: (item) =>
              !item?.retryOf && (
                <Button
                  disabled={(item.latestStatus || 1) / 100 === 2}
                  onClick={() =>
                    executionApi.remove({ id: item.id! })
                  }
                >
                  Retry
                </Button>
              ),
          },
        ]}
        context={{}}
        fields={[ 'latestStatus', 'message.topic', 'target.name' ]}
        filter={[
          {
            field: 'retryOf',
            op: Operation.Equals,
            value: undefined,
          },
        ]}
        query={Apis.Webhooks.Execution}
        searchConfig={searchConfig}
        onRowClick={(execution) => navigate(`/webhooks/executions/${execution.id}`)}
      />
    </Stack>
  );
};

export default ExecutionsPage;
