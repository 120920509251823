import { Button, FormControlLabel, FormGroup, Stack, Switch } from "@mui/material";
import React from "react";
import apiFor, { ApiError } from "../../../api/Api";
import { Apis } from "../../../api/Config";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import FormikField from "../../../components/FormikField";
import { ApizedFormProps } from "../../../components/ApizedListPage";
import { useParams } from "react-router-dom";
import { Device, NotificationType } from "../../../api/models/Notification";

const DeviceForm = (
  {
    isModal,
    onClose = () => null,
    selected
  }: ApizedFormProps<Device>
) => {
  const { enqueueSnackbar } = useSnackbar();
  const { application } = useParams();
  const api = apiFor(Apis.Notification.Device, { application });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: selected?.id || undefined,
      enabled: !!selected?.enabled,
      description: selected?.description,
      user: selected?.user,
      type: selected?.type || NotificationType.GCM,
      token: selected?.token,
    },
    validationSchema: Yup.object().shape({
      type: Yup.string().required(),
    }),
    onSubmit: (values) => {
      if (selected) {
        api.update({
          id: selected.id!,
          obj: { ...values },
        }).then((u) => {
          onClose(u);
          enqueueSnackbar(`Device '${u.id}' updated!`, { variant: "success" });
        }).catch((e: ApiError) => {
          e.errors.map(error => enqueueSnackbar(error.message, { variant: "error" }))
        })
      } else {
        api.create({
          obj: values,
        }).then((u) => {
          onClose(u);
          enqueueSnackbar(`Device '${u.id}' created!`, { variant: "success" });
        }).catch((e: ApiError) => {
          e.errors.map(error => enqueueSnackbar(error.message, { variant: "error" }))
        })
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={"1em"} bottom={"1em"}>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                name={`enabled`}
                checked={formik.values.enabled}
                onChange={formik.handleChange}
              />
            }
            label={"Enabled"}
            labelPlacement="start"
          />
        </FormGroup>
        <FormikField required formik={formik} label={"Description"} field={"description"} type={"text"}/>
        <FormikField required formik={formik} label={"User"} field={"user"} type={"text"}/>
        <FormikField
          required
          formik={formik}
          label={"Type"}
          field={"type"}
          type={"select"}
          options={Object.keys(NotificationType).map((o) => ({ label: o, value: o }))}
        />
        <FormikField multiline required formik={formik} label={"Token"} field={"token"} type={"text"}/>
        <Stack direction={"row"} spacing={"1em"} justifyContent={"right"}>
          {isModal && (<Button variant={"outlined"} onClick={() => onClose()}>Cancel</Button>)}
          <Button variant={"contained"} onClick={formik.submitForm}>{selected ? 'Update' : 'Create'}</Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default DeviceForm;