import React from 'react';
import { Breadcrumbs, Button, Stack, Typography } from "@mui/material";
import { SearchEntry } from "../../../components/search/Types";
import { Operation } from "../../../api/Search";
import { Apis } from "../../../api/Config";
import { Profile } from "../../../api/models/Vivi";
import ApizedListPage from "../../../components/ApizedListPage";
import { useNavigate } from "react-router-dom";

const ProfilesPage = () => {
  const navigate = useNavigate();

  const searchConfig: SearchEntry[] = [
    {
      id: 'name',
      label: "Name",
      operations: [
        { label: "is", value: Operation.Equals },
        { label: "is not", value: Operation.NotEquals },
        { label: "contains", value: Operation.Like },
      ],
      value: 'name',
      values: {
        label: 'name',
        value: 'name',
        query: {
          context: { token: '8fb4fa31-1a78-4d25-82dc-a9dda48fa7d8' },
          definition: Apis.Vivi.Profile
        }
      }
    },
    {
      id: 'email',
      label: "Email",
      operations: [
        { label: "is", value: Operation.Equals },
        { label: "is not", value: Operation.NotEquals },
        { label: "contains", value: Operation.Like },
      ],
      value: 'email',
      values: {
        label: 'email',
        value: 'email',
        query: {
          context: { token: '8fb4fa31-1a78-4d25-82dc-a9dda48fa7d8' },
          definition: Apis.Vivi.Profile,
          search: [ { field: "email", op: Operation.NotEquals } ]
        }
      }
    },
  ];

  return (
    <Stack spacing={"1em"}>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary">Profiles</Typography>
      </Breadcrumbs>
      <ApizedListPage<Profile>
        columns={[
          { label: 'Id', minWidth: 170, format: (value) => value.id },
          { label: 'Name', minWidth: 170, format: (value) => value.name },
          { label: 'Email', minWidth: 170, format: (value) => value.email },
          { label: 'ToS', minWidth: 60, format: (value) => value.acceptedTos },
          {
            label: '', minWidth: 60, format: (value) =>
              <Button
                variant={"contained"}
                onClick={(e) => {
                  e.stopPropagation();
                  return window.open(
                    `${window._env_.GRAFANA_URL}?orgId=1&var-job=vivi-server&var-name=All&var-user=${value.id}`,
                    "_blank"
                  )
                }}>
                Traces
              </Button>
          },
        ]}
        fields={[ '*', 'from.*', 'to.*' ]}
        context={{ token: '8fb4fa31-1a78-4d25-82dc-a9dda48fa7d8' }}
        query={Apis.Vivi.Profile}
        searchConfig={searchConfig}
        onRowClick={(profile) => navigate(`/vivi/profiles/${profile.id}`)}
      />
    </Stack>
  );
};

export default ProfilesPage;
