import { Button, Stack, TextField } from "@mui/material";
import React from "react";
import { Profile } from "../../../api/models/Vivi";
import { useSnackbar } from "notistack";
import apiFor, { ApiError } from "../../../api/Api";
import { Apis } from "../../../api/Config";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ApizedFormProps } from "../../../components/ApizedListPage";

const ProfileForm = (
  {
    isModal,
    onClose = () => null,
    selected
  }: ApizedFormProps<Profile>
) => {
  const { enqueueSnackbar } = useSnackbar();
  const api = apiFor(Apis.Vivi.Profile, { token: '8fb4fa31-1a78-4d25-82dc-a9dda48fa7d8' });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: selected?.id || undefined,
      name: selected?.name || '',
      email: selected?.email || '',
      acceptedTos: selected?.acceptedTos || '',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(),
      email: Yup.string().email().required(),
    }),
    onSubmit: (values) => {
      if (selected) {
        api.update({
          id: selected.id!,
          obj: values,
        }).then((u) => {
          onClose(u);
          enqueueSnackbar(`Profile '${u.name}' updated!`, { variant: "success" });
        }).catch((e: ApiError) => {
          e.errors.map(error => enqueueSnackbar(error.message, { variant: "error" }))
        })
      } else {
        api.create({
          obj: values,
        }).then((u) => {
          onClose(u);
          enqueueSnackbar(`Profile '${u.name}' created!`, { variant: "success" });
        }).catch((e: ApiError) => {
          e.errors.map(error => enqueueSnackbar(error.message, { variant: "error" }))
        })
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={"1em"} bottom={"1em"}>
        {selected &&
          <TextField
            disabled
            fullWidth
            label="Id"
            name="id"
            type="text"
            autoComplete={"none"}
            value={formik.values.id}
          />
        }
        <TextField
          fullWidth
          label="Name"
          name="name"
          type="text"
          autoComplete={"none"}
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />
        <TextField
          fullWidth
          label="Email"
          name="email"
          type="text"
          autoComplete={"none"}
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <TextField
          fullWidth
          label="Accepted ToS"
          name="acceptedTos"
          type="text"
          autoComplete={"none"}
          value={formik.values.acceptedTos}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.acceptedTos && Boolean(formik.errors.acceptedTos)}
          helperText={formik.touched.acceptedTos && formik.errors.acceptedTos}
        />
        <Stack direction={"row"} spacing={"1em"} justifyContent={"right"}>
          {isModal && (<Button variant={"outlined"} onClick={() => onClose()}>Cancel</Button>)}
          <Button variant={"contained"} onClick={formik.submitForm}>{selected ? 'Update' : 'Create'}</Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default ProfileForm;