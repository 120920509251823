import { Button, Stack, TextField } from "@mui/material";
import React from "react";
import { Order } from "../../../api/models/Vivi";
import { ApizedFormProps } from "../../../components/ApizedListPage";

const OrderForm = (
  {
    isModal,
    onClose = () => null,
    selected
  }: ApizedFormProps<Order>
) => {
  return (
    <Stack spacing={"1em"} bottom={"1em"}>
      <TextField
        disabled
        fullWidth
        label="Reference"
        name="reference"
        type="text"
        autoComplete={"none"}
        value={selected?.reference}
      />
      <TextField
        disabled
        fullWidth
        label="Owner"
        name="owner"
        type="text"
        autoComplete={"none"}
        value={selected?.owner}
      />
      <TextField
        disabled
        fullWidth
        label="External System"
        name="externalSystem"
        type="text"
        autoComplete={"none"}
        value={selected?.externalSystem}
      />
      <TextField
        disabled
        fullWidth
        label="External Reference"
        name="externalReference"
        type="text"
        autoComplete={"none"}
        value={selected?.externalReference}
      />
      <Stack direction={"row"} spacing={"1em"} justifyContent={"right"}>
        {isModal && (<Button variant={"outlined"} onClick={() => onClose()}>Cancel</Button>)}
      </Stack>
    </Stack>
  );
};

export default OrderForm;