import React from 'react';
import { Button, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { SearchEntry } from "../../../components/search/Types";
import { Operation } from "../../../api/Search";
import { Apis } from "../../../api/Config";
import { Fare, Location, Order, OrderItem, Profile, Ticket } from "../../../api/models/Vivi";
import ApizedListPage from "../../../components/ApizedListPage";
import { Async, AsyncProps } from "react-async";
import apiFor from "../../../api/Api";

const TicketsPage = () => {
  const navigate = useNavigate();

  const userCache: { [key: string]: { resolved?: Profile, cbs: [ (profile: Profile) => void ] } } = {};
  const profileApi = apiFor(Apis.Vivi.Profile, { token: '8fb4fa31-1a78-4d25-82dc-a9dda48fa7d8' });
  const resolveProfile = ({ owner }: AsyncProps<Profile>) => {
    return new Promise<Profile>((resolve) => {
      if (!userCache[owner]) {
        userCache[owner] = { cbs: [ resolve ] };
        profileApi.get({ id: owner, fields: [ 'name', 'email' ] }).then((p) => {
          userCache[owner].resolved = p;
          userCache[owner].cbs.forEach((r) => r(p));
        })
      } else if (userCache[owner].resolved) {
        resolve(userCache[owner].resolved!);
      } else {
        userCache[owner].cbs.push(resolve);
      }
    });
  };

  const searchConfig: SearchEntry[] = [
    {
      id: 'email',
      label: "User email",
      operations: [
        { label: "is", value: Operation.Equals },
        { label: "is not", value: Operation.NotEquals },
      ],
      value: 'owner',
      values: {
        label: 'email',
        value: 'id',
        query: {
          context: { token: '8fb4fa31-1a78-4d25-82dc-a9dda48fa7d8' },
          definition: Apis.Vivi.Profile,
          search: [ { field: 'email', op: Operation.NotEquals } ]
        }
      }
    },
    {
      id: 'booking_reference',
      label: "Booking Reference",
      operations: [
        { label: "is", value: Operation.Equals },
        { label: "is not", value: Operation.NotEquals },
      ],
      value: 'reference',
      values: {
        label: 'reference',
        value: 'reference',
        query: {
          context: { token: '8fb4fa31-1a78-4d25-82dc-a9dda48fa7d8' },
          definition: Apis.Vivi.Ticket
        }
      }
    },
    {
      id: 'reference',
      label: "Reference",
      operations: [
        { label: "is", value: Operation.Equals },
        { label: "is not", value: Operation.NotEquals },
      ],
      value: 'bookingReference',
      values: {
        label: 'bookingReference',
        value: 'bookingReference',
        query: {
          context: { token: '8fb4fa31-1a78-4d25-82dc-a9dda48fa7d8' },
          definition: Apis.Vivi.Ticket
        }
      }
    },
  ];

  return (
    <Stack spacing={"1em"}>
      <ApizedListPage<Ticket>
        columns={[
          {
            label: 'References',
            minWidth: 80,
            format: (value) => (<div>
              <span>{value.reference}</span>
              <hr/>
              <span>{((value.orderItem as OrderItem).order as Order).externalReference}</span>
            </div>)
          },
          {
            label: 'From',
            minWidth: 150,
            format: (value) => {
              const orderItem = value.orderItem as OrderItem;
              const fare = orderItem.fare as Fare;
              const from = (fare as Fare).from as Location;
              return `${from.name} (${from?.value})`
            }
          }, {
            label: 'To',
            minWidth: 150,
            format: (value) => {
              const orderItem = value.orderItem as OrderItem;
              const fare = orderItem.fare as Fare;
              let to = (fare as Fare).to as Location;
              return `${to?.name} (${to?.value})`
            }
          },
          {
            label: 'Price',
            minWidth: 60,
            format: (value) => {
              const orderItem = value.orderItem as OrderItem;
              const fare = orderItem.fare as Fare;
              return `${(fare as Fare).price}${fare.currency === 'GBP' ? '£' : fare.currency}`
            }
          },
          {
            label: 'Owner',
            minWidth: 160,
            format: (value) => {
              const orderItem = value.orderItem as OrderItem;
              const order = orderItem.order as Order;
              return <Async promiseFn={resolveProfile} owner={order.owner!}>
                {({ data }) => data ? `${data?.name} (${data.email})` :
                  <div className={"shimmer"} style={{ width: '100%', height: '1.25em' }}/>}
              </Async>
            }
          },
          {
            label: 'Actions', minWidth: 20, format: (value) =>
              <Stack direction="row" spacing="1em" justifyContent={"right"}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    const orderItem = value.orderItem as OrderItem;
                    const order = orderItem.order as Order;
                    navigate(`/vivi/orders/${order.id}`);
                  }}>
                  order
                </Button>
              </Stack>
          }
        ]}
        fields={[ 'reference', 'orderItem.order.owner', 'orderItem.order.externalReference', 'orderItem.fare.from.name', 'orderItem.fare.from.value', 'orderItem.fare.to.name', 'orderItem.fare.to.value', 'orderItem.fare.price', 'orderItem.fare.currency' ]}
        context={{ token: '8fb4fa31-1a78-4d25-82dc-a9dda48fa7d8' }}
        query={Apis.Vivi.Ticket}
        onRowClick={(collection) => navigate(`/vivi/tickets/${collection.id}`)}
        searchConfig={searchConfig}
      />
    </Stack>
  );
};

export default TicketsPage;
