import React from 'react';
import { IconButton, Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import { SearchEntry } from "../../../components/search/Types";
import { Operation } from "../../../api/Search";
import { Apis } from "../../../api/Config";
import ApizedListPage from "../../../components/ApizedListPage";
import { convertTimeZone, toDateTimeString } from "../../../lib/DateTime";
import { Device } from "../../../api/models/Notification";
import DeviceForm from "../components/DeviceForm";
import { Delete } from "@mui/icons-material";
import apiFor, { ApiError } from "../../../api/Api";
import { useSnackbar } from "notistack";

const DevicesPage = () => {
  const { application } = useParams<any>();
  const snackbar = useSnackbar();
  const deviceApi = apiFor(Apis.Notification.Device, { application });
  const [refetchTrigger, setRefetchTrigger] = React.useState(new Date().getTime());

  const searchConfig: SearchEntry[] = [
    {
      id: 'address',
      label: "Address",
      operations: [
        { label: "contains", value: Operation.Like },
        { label: "is", value: Operation.Equals },
        { label: "is not", value: Operation.NotEquals },
      ],
      value: 'address',
      values: {
        label: 'address',
        value: 'address',
        query: {
          context: { application },
          definition: Apis.Notification.Device
        },
      }
    },
  ];

  return (
    <Stack spacing={"1em"}>
      <ApizedListPage<Device>
        columns={[
          {
            label: 'Created',
            minWidth: 170,
            format: (value) => toDateTimeString(convertTimeZone({
              date: new Date(value.createdAt!),
              from: 'UTC',
              to: ''
            }))
          },
          { label: 'Enabled', minWidth: 10, format: (value) => value.enabled ? 'Yes' : 'No' },
          { label: 'Description', minWidth: 170, format: (value) => value.description },
          { label: 'User', minWidth: 170, format: (value) => value.user },
          { label: 'Type', minWidth: 170, format: (value) => value.type },
          { label: 'Token', minWidth: 170, format: (value) => value.token?.substring(0, 20) + "..." },
          {
            label: '', minWidth: 10, format: (value) => (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  if (window.confirm("Are you sure?")) {
                    deviceApi.remove({ id: value.id! })
                      .then(() => {
                        snackbar.enqueueSnackbar(`Device ${value.id} deleted.`, { variant: "success" });
                        setRefetchTrigger((new Date()).getTime());
                      })
                      .catch((e: ApiError) => {
                        console.log(e)
                        e.errors.map((error) => snackbar.enqueueSnackbar(error.message, { variant: "error" }))
                      });
                  }
                }}
              >
                <Delete/>
              </IconButton>
            )
          },
        ]}
        form={DeviceForm}
        fields={[ '*', 'template.name' ]}
        context={{ application }}
        query={Apis.Notification.Device}
        searchConfig={searchConfig}
        refetchTrigger={refetchTrigger}
      />
    </Stack>
  );
};

export default DevicesPage;
