import React, { useState } from 'react';
import { Button, Stack } from "@mui/material";
import { SearchEntry } from "../../../components/search/Types";
import { Operation } from "../../../api/Search";
import { Apis } from "../../../api/Config";
import ApizedListPage from "../../../components/ApizedListPage";
import ApplicationForm from "../components/ApplicationForm";
import apiFor, { ApiError } from "../../../api/Api";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { Application } from "../../../api/models/Notification";

const ApplicationsPage = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const collectionApi = apiFor(Apis.Notification.Application);
  const [ refetchTrigger, setRefetchTrigger ] = useState(0)

  const searchConfig: SearchEntry[] = [
    {
      id: 'name',
      label: "Name",
      operations: [
        { label: "contains", value: Operation.Like },
        { label: "is", value: Operation.Equals },
        { label: "is not", value: Operation.NotEquals },
      ],
      value: 'name',
      values: {
        label: 'name',
        value: 'name',
        query: {
          context: {},
          definition: Apis.Notification.Application
        },
      }
    },
  ];

  return (
    <Stack spacing={"1em"}>
      <ApizedListPage<Application>
        columns={[
          { label: 'Name', minWidth: 200, format: (value) => value.name },
          {
            label: 'Actions', minWidth: 20, format: (value) =>
              <Stack direction="row" spacing="1em" justifyContent={"right"}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/notification/applications/${value.id}/devices`);
                  }}>
                  devices
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/notification/applications/${value.id}/messages`);
                  }}>
                  messages
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (window.confirm(`Are you sure you wish to delete collection '${value.name}'?`)) {
                      collectionApi.remove({ id: value.id! })
                        .then(() => {
                          setRefetchTrigger((new Date()).getTime());
                          enqueueSnackbar(`Collection '${value.name}' deleted!`, { variant: "success" });
                        })
                        .catch((e: ApiError) => {
                          e.errors.map(error => enqueueSnackbar(error.message, { variant: "error" }))
                        });
                    }
                  }}>
                  delete
                </Button>
              </Stack>
          },
        ]}
        fields={[ 'name', 'slug', 'configurations.type', 'configurations.credentials' ]}
        context={{}}
        query={Apis.Notification.Application}
        form={ApplicationForm}
        searchConfig={searchConfig}
        refetchTrigger={refetchTrigger}
      />
    </Stack>
  );
};

export default ApplicationsPage;
