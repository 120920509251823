import { deepPurple } from "@mui/material/colors";
import { Stack } from "@mui/material";
import styled from "styled-components";

const WeekDay = styled(Stack)`
  background-color: white;
  border-radius: 2em;
  width: 2em;
  height: 2em;
  text-align: center;
  padding-bottom: 0.25em;

  span {
    margin-top: 0.45em;
  }

  &.active {
    border-radius: 0.75em;
    color: white;
    background-color: ${deepPurple[400]};
  }
`;

export default WeekDay;