import React from 'react';
import { Chip, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { SearchEntry } from "../../../components/search/Types";
import { Operation } from "../../../api/Search";
import { Apis } from "../../../api/Config";
import { Order, OrderItem, OrderStatus, Profile, Ticket } from "../../../api/models/Vivi";
import ApizedListPage from "../../../components/ApizedListPage";
import apiFor from "../../../api/Api";
import { Async, AsyncProps } from "react-async";

const OrdersPage = () => {
  const navigate = useNavigate();

  const userCache: { [key: string]: { resolved?: Profile, cbs: [ (profile: Profile) => void ] } } = {};
  const profileApi = apiFor(Apis.Vivi.Profile, { token: '8fb4fa31-1a78-4d25-82dc-a9dda48fa7d8' });
  const resolveProfile = ({ owner }: AsyncProps<Profile>) => {
    return new Promise<Profile>((resolve) => {
      if (!userCache[owner]) {
        userCache[owner] = { cbs: [ resolve ] };
        profileApi.get({ id: owner, fields: [ 'name', 'email' ] }).then((p) => {
          userCache[owner].resolved = p;
          userCache[owner].cbs.forEach((r) => r(p));
        })
      } else if (userCache[owner].resolved) {
        resolve(userCache[owner].resolved!);
      } else {
        userCache[owner].cbs.push(resolve);
      }
    });
  };

  const searchConfig: SearchEntry[] = [
    {
      id: 'status',
      label: "Status",
      operations: [
        { label: "is", value: Operation.Equals },
        { label: "is not", value: Operation.NotEquals },
      ],
      value: 'status',
      values: Object.keys(OrderStatus).map((s) => ({ label: s, value: s }))
    },
    {
      id: 'email',
      label: "User Email",
      operations: [
        { label: "is", value: Operation.Equals },
        { label: "is not", value: Operation.NotEquals },
      ],
      value: 'owner',
      values: {
        label: 'email',
        value: 'id',
        query: {
          context: { token: '8fb4fa31-1a78-4d25-82dc-a9dda48fa7d8' },
          definition: Apis.Vivi.Profile,
          search: [ { field: 'email', op: Operation.NotEquals } ]
        }
      }
    },
    {
      id: 'reference',
      label: "Reference",
      operations: [
        { label: "is", value: Operation.Equals },
        { label: "is not", value: Operation.NotEquals },
        { label: "contains", value: Operation.Like },
      ],
      value: 'reference',
      values: {
        label: 'reference',
        value: 'reference',
        query: {
          context: { token: '8fb4fa31-1a78-4d25-82dc-a9dda48fa7d8' },
          definition: Apis.Vivi.Order
        }
      }
    },
    {
      id: 'external_system',
      label: "External System",
      operations: [
        { label: "is", value: Operation.Equals },
        { label: "is not", value: Operation.NotEquals },
      ],
      value: 'tickets.id',
      values: {
        label: 'externalSystem',
        value: 'id',
        query: {
          context: { token: '8fb4fa31-1a78-4d25-82dc-a9dda48fa7d8' },
          definition: Apis.Vivi.Order,
          search: [ { field: 'externalSystem', op: Operation.NotEquals } ]
        }
      }
    },
    {
      id: 'external_reference',
      label: "External Reference",
      operations: [
        { label: "is", value: Operation.Equals },
        { label: "is not", value: Operation.NotEquals },
      ],
      value: 'tickets.id',
      values: {
        label: 'externalReference',
        value: 'id',
        query: {
          context: { token: '8fb4fa31-1a78-4d25-82dc-a9dda48fa7d8' },
          definition: Apis.Vivi.Order,
          search: [ { field: 'externalReference', op: Operation.NotEquals } ]
        }
      }
    },
    {
      id: 'ticket_reference',
      label: "Ticket Reference",
      operations: [
        { label: "is", value: Operation.Equals },
        { label: "is not", value: Operation.NotEquals },
      ],
      value: 'tickets.id',
      values: {
        label: 'reference',
        value: 'id',
        query: {
          context: { token: '8fb4fa31-1a78-4d25-82dc-a9dda48fa7d8' },
          definition: Apis.Vivi.Ticket,
          search: [ { field: 'reference', op: Operation.NotEquals } ]
        }
      }
    },
  ];

  return (
    <Stack spacing={"1em"}>
      <ApizedListPage<Order>
        columns={[
          {
            label: 'Email',
            minWidth: 170,
            format: (value) =>
              <Async promiseFn={resolveProfile} owner={value.owner!}>
                {({ data }) => data ? data.email :
                  <div className={"shimmer"} style={{ width: '100%', height: '1.25em' }}/>}
              </Async>
          },
          {
            label: "Reference",
            sort: 'reference',
            minWidth: 70,
            format: (value) => value.reference
          },
          {
            label: "External System",
            sort: 'externalSystem',
            minWidth: 60,
            format: (value) => value.externalSystem
          },
          {
            label: "External Reference",
            sort: 'externalReference',
            minWidth: 280,
            format: (value) => value.externalReference
          },
          {
            label: "Tickets",
            minWidth: 80,
            format: (value) => {
              return (value.orderItems as OrderItem[]).reduce((a, i) => a.concat(i.tickets as Ticket[]), [] as Ticket[])
                .map(t => t.reference).join(', ')
            }
          },
          {
            label: "Total",
            sort: 'total',
            minWidth: 60,
            format: (value) => value.total ? `${value.total / 100} ${value.currency === 'GBP' ? '£' : value.currency}` : '-'
          },
          {
            label: "Paid",
            minWidth: 60,
            format: (value) => value.paid ? `${value.paid / 100} ${value.currency === 'GBP' ? '£' : value.currency}` : '-'
          },
          {
            label: "Status",
            sort: 'status',
            minWidth: 100,
            format: (value) => {
              switch (value.status) {
                case OrderStatus.PENDING:
                  return <Chip color={"info"} label={value.status}/>
                case OrderStatus.CANCELLED:
                  return <Chip color={"warning"} label={value.status}/>
                case OrderStatus.COMPLETE:
                  return <Chip color={"success"} label={value.status}/>
                default:
                  return <Chip label={value.status}/>
              }
            }
          }
        ]}
        fields={[ 'owner', 'reference', 'externalSystem', 'externalReference', 'currency', 'total', 'paid', 'status', 'orderItems.tickets.fare.name', 'orderItems.tickets.reference' ]}
        context={{ token: '8fb4fa31-1a78-4d25-82dc-a9dda48fa7d8' }}
        query={Apis.Vivi.Order}
        onRowClick={(collection) => navigate(`/vivi/orders/${collection.id}`)}
        searchConfig={searchConfig}
      />
    </Stack>
  );
};

export default OrdersPage;
