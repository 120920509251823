import React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Breadcrumbs, Button, Link, Stack, Typography } from "@mui/material";
import { useApiGet } from "../../../api/ApiHooks";
import { Apis } from "../../../api/Config";
import MessageForm from "../components/MessageForm";
import BorderedSection from "../../../atoms/BorderedSection";
import ApizedAudit from "../../../components/audit/ApizedAudit";
import apiFor, { Service } from "../../../api/Api";
import { Device, Message, Notification } from "../../../api/models/Notification";

const MessagePage = () => {
  const navigate = useNavigate();
  const { id, application } = useParams<any>();
  const { data: message, loading } = useApiGet<Message>(
    Apis.Notification.Message,
    { application },
    {
      id,
      fields: [ '*', 'notifications.*', 'notifications.device.*' ]
    }
  )

  return (
    <Stack spacing={"1em"} padding={"1em"}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href={`#/notification/applications/${application}/messages`}>
            Messages
          </Link>
          <Typography color="text.primary">Message <b>{message?.id}</b></Typography>
        </Breadcrumbs>
        <Stack direction={"row"} spacing={"1em"}>
          <Button
            variant={"outlined"}
            onClick={() => {
              apiFor(Apis.Notification.Message, { application }).create({
                obj: { ...message, id: undefined, notifications: undefined }
              }).then(() => {
                navigate(`/notification/applications/${application}/messages`);
              });
            }}
          >
            Resend
          </Button>
          <ApizedAudit service={Service.Notification} entity={"messages"} target={message?.id}/>
        </Stack>
      </Stack>
      {!loading && message && (
        <>
          <BorderedSection title={"Details"}>
            <MessageForm selected={message}/>
          </BorderedSection>
          <BorderedSection title={"Notifications"}>
            {(message.notifications as Notification[])?.map((notification) => {
              const device = notification.device as Device;
              return (
                <BorderedSection key={notification.id} title={`${device.description} (${device.type})`}>
                  <div>{JSON.stringify(notification.payload, null, 2)}</div>
                </BorderedSection>
              );
            })}
          </BorderedSection>
        </>
      )}
    </Stack>
  );
};

export default MessagePage;
