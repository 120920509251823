import { ApizedDefinition, Service } from './Api';
import { AuthOauth, AuthRole, AuthToken, AuthUser, LoginRequest } from "./models/Auth";
import { ApizedAuditEntry, Model } from "./models/Base";
import { Collection, Message as EmailMessage, Template } from "./models/Email";
import {
  Alert,
  Favourite,
  FeatureFlag,
  Info,
  Journey,
  Order,
  Profile,
  Route,
  Ticket,
  Trip,
  Version
} from "./models/Vivi";
import { Application, Device, Message as NotificationMessage } from "./models/Notification";
import { Execution, Message as WebhooksMessage, Source, Target } from "./models/Webhooks";

export const Apis = {
  Auth: {
    AuditInstance: {
      path: '/audit/[entity]/[target]',
      service: Service.Auth,
    } as ApizedDefinition<ApizedAuditEntry>,
    Me: {
      path: '/tokens',
      service: Service.Auth,
    } as ApizedDefinition<AuthUser>,
    User: {
      path: '/users',
      service: Service.Auth,
    } as ApizedDefinition<AuthUser>,
    NonExpiringToken: {
      path: '/tokens/[user]?expiring=false',
      service: Service.Auth,
    } as ApizedDefinition<AuthToken>,
    ExpiringToken: {
      path: '/tokens/[user]?expiring=true',
      service: Service.Auth,
    } as ApizedDefinition<AuthToken>,
    Role: {
      path: '/roles',
      service: Service.Auth,
    } as ApizedDefinition<AuthRole>,
    Login: {
      path: '/tokens',
      service: Service.Auth,
    } as ApizedDefinition<LoginRequest>,
    OauthLogin: {
      path: '/tokens/oauth',
      service: Service.Auth,
    } as ApizedDefinition<Model>,
    Oauth: {
      path: '/oauths',
      service: Service.Auth,
    } as ApizedDefinition<AuthOauth>
  },
  Email: {
    Collection: {
      path: '/collections',
      service: Service.Email,
    } as ApizedDefinition<Collection>,
    Message: {
      path: '/collections/[collection]/messages',
      service: Service.Email,
    } as ApizedDefinition<EmailMessage>,
    Template: {
      path: '/collections/[collection]/templates',
      service: Service.Email,
    } as ApizedDefinition<Template>
  },
  Notification: {
    Application: {
      path: '/applications',
      service: Service.Notification,
    } as ApizedDefinition<Application>,
    Device: {
      path: '/applications/[application]/devices',
      service: Service.Notification,
    } as ApizedDefinition<Device>,
    Message: {
      path: '/applications/[application]/messages',
      service: Service.Notification,
    } as ApizedDefinition<NotificationMessage>,
  },
  Vivi: {
    Info: {
      path: '/info',
      service: Service.Vivi,
    } as ApizedDefinition<Info>,
    Profile: {
      path: '/users',
      service: Service.Vivi,
    } as ApizedDefinition<Profile>,
    Journey: {
      path: '/journeys',
      service: Service.Vivi,
    } as ApizedDefinition<Journey>,
    Order: {
      path: '/orders',
      service: Service.Vivi,
    } as ApizedDefinition<Order>,
    Alert: {
      path: '/alerts',
      service: Service.Vivi,
    } as ApizedDefinition<Alert>,
    Trip: {
      path: '/trips',
      service: Service.Vivi,
    } as ApizedDefinition<Trip>,
    Ticket: {
      path: '/tickets',
      service: Service.Vivi,
    } as ApizedDefinition<Ticket>,
    Version: {
      path: '/versions',
      service: Service.Vivi,
    } as ApizedDefinition<Version>,
    Route: {
      path: '/routes',
      service: Service.Vivi,
    } as ApizedDefinition<Route>,
    Suggestion: {
      path: '/suggestions',
      service: Service.Vivi,
    } as ApizedDefinition<Trip>,
    Favourite: {
      path: '/favourites',
      service: Service.Vivi,
    } as ApizedDefinition<Favourite>,
    FeatureFlag: {
      path: '/features',
      service: Service.Vivi,
    } as ApizedDefinition<FeatureFlag>,
  },
  Webhooks: {
    Target: {
      path: '/targets',
      service: Service.Webhooks,
    } as ApizedDefinition<Target>,
    Source: {
      path: '/sources',
      service: Service.Webhooks,
    } as ApizedDefinition<Source>,
    Message: {
      path: '/messages',
      service: Service.Webhooks,
    } as ApizedDefinition<WebhooksMessage>,
    Execution: {
      path: '/executions',
      service: Service.Webhooks,
    } as ApizedDefinition<Execution>,
  }
};
