import React, { ReactElement } from "react";
import { ApizedFormProps } from "../../../components/ApizedListPage";
import { useSnackbar } from "notistack";
import apiFor, { ApiError } from "../../../api/Api";
import { Apis } from "../../../api/Config";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, FormControlLabel, FormGroup, Stack, Switch } from "@mui/material";
import FormikField from "../../../components/FormikField";
import { AuthOauth } from "../../../api/models/Auth";
import { Source } from "../../../api/models/Webhooks";

const SourceForm = ({
  isModal,
  onClose = () => null,
  selected
}: ApizedFormProps<Source>): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();
  const api = apiFor(Apis.Webhooks.Source);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: selected?.id || undefined,
      active: selected?.active || false,
      name: selected?.name || '',
      secret: selected?.secret || '',
      topic: selected?.topic || '',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required()
        .min(3)
        .max(255),
      topic: Yup.string()
        .required()
        .min(3)
        .max(255),
      secret: Yup.string()
        .required()
        .min(6)
        .max(255),
    }),
    onSubmit: (values) => {
      if (selected) {
        api.update({
          id: selected.id!,
          obj: values as AuthOauth,
        }).then((u) => {
          onClose(u);
          enqueueSnackbar(`Source '${u.name}' updated!`, { variant: "success" });
        }).catch((e: ApiError) => {
          e.errors.map(error => enqueueSnackbar(error.message, { variant: "error" }))
        })
      } else {
        api.create({
          obj: values as AuthOauth,
        }).then((u) => {
          onClose(u);
          enqueueSnackbar(`Source '${u.name}' created!`, { variant: "success" });
        }).catch((e: ApiError) => {
          e.errors.map(error => enqueueSnackbar(error.message, { variant: "error" }))
        })
      }
    }
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={"1em"} bottom={"1em"}>
        {selected?.id && <FormikField
          disabled
          required
          formik={formik}
          label={"Id"}
          field={"id"}
          type={"text"}
        />}
        <FormikField
          disabled={!isModal}
          required
          formik={formik}
          label={"Name"}
          field={"name"}
          type={"text"}
        />
        <FormikField
          disabled={!isModal}
          required
          formik={formik}
          label={"Topic"}
          field={"topic"}
          type={"text"}
        />
        <FormikField
          disabled={!isModal}
          required
          formik={formik}
          label={"Secret"}
          field={"secret"}
          type={"text"}
        />
        <FormGroup style={{ alignItems: "flex-start" }}>
          <FormControlLabel
            control={
              <Switch
                disabled={!isModal}
                name={"active"}
                checked={formik.values.active}
                onChange={formik.handleChange}
              />
            }
            label={"Active"}
            labelPlacement="start"
          />
        </FormGroup>
        <Stack direction={"row"} spacing={"1em"} justifyContent={"right"}>
          {isModal && (<Button variant={"outlined"} onClick={() => onClose()}>Cancel</Button>)}
          {isModal && (
            <Button variant={"contained"} onClick={formik.submitForm}>{selected ? 'Update' : 'Create'}</Button>)}
        </Stack>
      </Stack>
    </form>
  );
};

export default SourceForm;
