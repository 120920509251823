import React, { useEffect, useMemo, useState } from 'react';
import './App.css';
import UserContextProvider from "./context/UserContextProvider";
import MainNavigation from "./components/MainNavigation";
import { HashRouter } from "react-router-dom";
import { Stack } from "@mui/material";
import { SnackbarProvider } from "notistack";
import SocialLogin from "./components/SocialLogin";
import EmailApp from "./app/email/EmailApp";
import AuthApp from "./app/auth/AuthApp";
import ViviApp from "./app/vivi/ViviApp";
import Main from "./components/Main";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { Application, MenuState } from "./lib/Types";
import { useEventBus } from "./lib/bus/EventBus";
import NotificationApp from "./app/notification/NotificationApp";
import WebhooksApp from "./app/webhooks/WebhooksApp";

declare global {
  interface Window {
    ApplePaySession: any
    _env_: {
      AUTH_SERVER_URL: string
      EMAIL_SERVER_URL: string
      NOTIFICATION_SERVER_URL: string
      VIVI_SERVER_URL: string
      WEBHOOKS_SERVER_URL: string
      GRAFANA_URL: string
    }
  }

  interface Array<T> {
    unique(key: (t: T) => string): T[];
  }
}

if (!Array.prototype.unique) {
  // eslint-disable-next-line no-extend-native
  Array.prototype.unique = function unique<T extends {}>(key: (t: T) => string): T[] {
    const map: { [key: string]: T } = {};
    const coll: T[] = [];
    this.forEach((o) => {
      if (!map[key(o)]) {
        map[key(o)] = o;
        coll.push(o);
      }
    });
    return coll;
  };
}


const App = () => {
  const isModal = window.location.pathname.startsWith('/auth/login/');
  const eventBus = useEventBus();
  const [ applications, setApplications ] = useState([
    AuthApp,
    EmailApp,
    NotificationApp,
    WebhooksApp,
    ViviApp
  ]);

  const appEnricher = () => {
    const updates: Application[] = [];
    let count = 0;

    const complete = () => {
      if (count === 0) {
        setApplications(updates);
      }
    };

    applications.map(((application, idx) => {
      if (application.extra) {
        count++;
        application.extra().then((a) => {
          count--;
          complete();
          updates[idx] = a
        }).catch(() => {
          count--;
          updates[idx] = application;
        });
      } else {
        updates[idx] = application;
      }
      return undefined;
    }));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => eventBus.effect('login-success', appEnricher), [ eventBus ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useMemo(appEnricher, []);

  const [ state, setState ] = useState<MenuState>({
    application: applications[0],
    menu: 0
  });

  return isModal
    ? <SocialLogin/>
    : <LocalizationProvider dateAdapter={AdapterDayjs}>
      <UserContextProvider>
        <SnackbarProvider anchorOrigin={{ horizontal: 'center', vertical: "bottom" }}>
          <Stack spacing={"1em"}>
            <HashRouter>
              <MainNavigation
                applications={applications}
                menuState={state}
                onChange={setState}
              />
              <Main
                applications={applications}
                menuState={state}
                onChange={setState}
              />
            </HashRouter>
          </Stack>
        </SnackbarProvider>
      </UserContextProvider>
    </LocalizationProvider>;
}

export default App;
