import React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Breadcrumbs, Button, Link, Stack, TextField, Typography } from "@mui/material";
import { useApiGet } from "../../../api/ApiHooks";
import { Apis } from "../../../api/Config";
import { Favourite, Journey } from "../../../api/models/Vivi";
import BorderedSection from "../../../atoms/BorderedSection";
import { convertTimeZone, toDateTimeString } from "../../../lib/DateTime";
import ApizedAudit from "../../../components/audit/ApizedAudit";
import { Service } from "../../../api/Api";

const JourneyPage = () => {
  const { id } = useParams<any>();
  const navigate = useNavigate();

  const { data: journey, loading } = useApiGet<Journey>(
    Apis.Vivi.Journey,
    { token: '8fb4fa31-1a78-4d25-82dc-a9dda48fa7d8' },
    {
      id,
      fields: [ 'owner', 'mode', 'date', 'from.*', 'to.*' ],
    }
  )

  return (
    <Stack spacing={"1em"}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="#/vivi/journeys">
            Journeys
          </Link>
          <Typography color="text.primary">Journey <b>{journey?.id}</b></Typography>
        </Breadcrumbs>
        <Stack direction={"row"} spacing={"1em"}>
          <Button variant={"contained"} onClick={() => navigate(`/vivi/profiles/${journey.owner}`)}>Profile</Button>
          <ApizedAudit service={Service.Vivi} entity={"journeys"} target={id}/>
        </Stack>
      </Stack>
      {!loading && journey && (
        <>
          <BorderedSection title={"From"}>
            <Stack spacing={"1em"}>
              <TextField
                fullWidth
                disabled
                label={"Name"}
                type={"text"}
                value={(journey.from as Favourite).name}
              />
              <TextField
                fullWidth
                disabled
                label={"Type"}
                type={"text"}
                value={(journey.from as Favourite).type}
              />
              <TextField
                fullWidth
                disabled
                label={"Address"}
                type={"text"}
                value={(journey.from as Favourite).address}
              />
              <TextField
                fullWidth
                disabled
                label={"Value"}
                type={"text"}
                value={(journey.from as Favourite).value}
              />
            </Stack>
          </BorderedSection>
          <BorderedSection title={"To"}>
            <Stack spacing={"1em"}>
              <TextField
                fullWidth
                disabled
                label={"Name"}
                type={"text"}
                value={(journey.to as Favourite).name}
              />
              <TextField
                fullWidth
                disabled
                label={"Type"}
                type={"text"}
                value={(journey.to as Favourite).type}
              />
              <TextField
                fullWidth
                disabled
                label={"Address"}
                type={"text"}
                value={(journey.to as Favourite).address}
              />
              <TextField
                fullWidth
                disabled
                label={"Value"}
                type={"text"}
                value={(journey.to as Favourite).value}
              />
            </Stack>
          </BorderedSection>
          <BorderedSection title={"Date"}>
            <Stack spacing={"1em"}>
              <TextField
                fullWidth
                disabled
                label={"Mode"}
                type={"text"}
                value={journey.mode}
              />
              <TextField
                fullWidth
                disabled
                label={"Date"}
                type={"text"}
                value={toDateTimeString(convertTimeZone({
                  date: new Date(journey.date!),
                  from: "UTC",
                  to: "Europe/London"
                }))}
              />
            </Stack>
          </BorderedSection>
        </>
      )}
    </Stack>
  );
};

export default JourneyPage;
