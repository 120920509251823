import { Application } from "../../lib/Types";
import MessagesPage from "./pages/MessagesPage";
import React from "react";
import { Navigate } from "react-router-dom";
import TargetsPage from "./pages/TargetsPage";
import ExecutionsPage from "./pages/ExecutionsPage";
import ExecutionPage from "./pages/ExecutionPage";
import SourcesPage from "./pages/SourcesPage";

const WebhooksApp: Application = {
  name: "Webhooks",
  path: "webhooks",
  menus: [
    {
      path: '/targets',
      description: "Targets",
    },
    {
      path: '/sources',
      description: "Sources",
    },
    {
      path: '/messages',
      description: "Messages",
    },
    {
      path: '/executions',
      description: "Executions",
    },

  ],
  routes: [
    {
      path: '/',
      element: <Navigate to={'/webhooks/messages'} replace/>,
    },
    {
      path: '/targets',
      element: <TargetsPage/>,
    },
    {
      path: '/sources',
      element: <SourcesPage/>,
    },
    {
      path: '/messages',
      element: <MessagesPage/>,
    },
    {
      path: '/executions',
      element: <ExecutionsPage/>,
    },
    {
      path: '/executions/:id',
      element: <ExecutionPage/>,
    },
  ]
};

export default WebhooksApp;