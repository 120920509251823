import { Application, MenuEntry } from "../../lib/Types";
import ApplicationsPage from "./pages/ApplicationsPage";
import MessagesPage from "./pages/MessagesPage";
import MessagePage from "./pages/MessagePage";
import React from "react";
import { Apis } from "../../api/Config";
import apiFor from "../../api/Api";
import { Navigate } from "react-router-dom";
import DevicesPage from "./pages/DevicesPage";

let dynamicMenus: MenuEntry[] = [];

const updateDynamicRoutes = (resolve: () => void, reject: () => void) => {
  apiFor(Apis.Notification.Application)
    .list({ fields: [ 'name', 'id' ] })
    .then((r) => {
      dynamicMenus = [];
      r.content.forEach((application) => {
        dynamicMenus.push({
          path: `/applications/${application.id}/devices`,
          description: `Devices (${application.name})`,
        });
        dynamicMenus.push({
          path: `/applications/${application.id}/messages`,
          description: `Messages (${application.name})`,
        });
      });
      resolve();
    }).catch(() => {
    dynamicMenus = [];
    reject();
  });
}

const NotificationApp: Application = {
  name: "Notification",
  path: "notification",
  menus: [
    {
      path: '/applications',
      description: "Applications",
    }
  ],
  routes: [
    {
      path: '/',
      element: <Navigate to={'/notification/applications'} replace/>,
    },
    {
      path: '/applications',
      element: <ApplicationsPage/>,
    },
    {
      path: '/applications/:application/devices',
      element: <DevicesPage/>,
    },
    {
      path: '/applications/:application/messages',
      element: <MessagesPage/>,
    },
    {
      path: '/applications/:application/messages/:id',
      element: <MessagePage/>,
    },
  ],
  extra: () => new Promise<Application>((resolve, reject) => updateDynamicRoutes(() => resolve({
    ...NotificationApp,
    menus: NotificationApp.menus.concat(dynamicMenus)
  }), reject))
};

export default NotificationApp;