import { Button, Stack } from "@mui/material";
import React from "react";
import { Version, VersionType } from "../../../api/models/Vivi";
import { useSnackbar } from "notistack";
import apiFor, { ApiError } from "../../../api/Api";
import { Apis } from "../../../api/Config";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ApizedFormProps } from "../../../components/ApizedListPage";
import FormikField from "../../../components/FormikField";

const VersionForm = (
  {
    isModal,
    onClose = () => null,
    selected
  }: ApizedFormProps<Version>
) => {
  const { enqueueSnackbar } = useSnackbar();
  const api = apiFor(Apis.Vivi.Version, { token: '8fb4fa31-1a78-4d25-82dc-a9dda48fa7d8' });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: selected?.id || undefined,
      type: selected?.type || '',
      semVer: selected?.semVer || '',
    },
    validationSchema: Yup.object().shape({
      type: Yup.mixed<VersionType>().oneOf(Object.values(VersionType)).required(),
      semVer: Yup.string().trim().matches(/\d+\.\d+\.\d+/, 'Incorrect format').required(),
    }),
    onSubmit: (values) => {
      if (selected) {
        api.update({
          id: selected.id!,
          obj: values as Version,
        }).then((u) => {
          onClose(u);
          enqueueSnackbar(`Version '${u.type}' updated!`, { variant: "success" });
        }).catch((e: ApiError) => {
          e.errors.map(error => enqueueSnackbar(error.message, { variant: "error" }))
        })
      } else {
        api.create({
          obj: values as Version,
        }).then((u) => {
          onClose(u);
          enqueueSnackbar(`Version '${u.type}' created!`, { variant: "success" });
        }).catch((e: ApiError) => {
          e.errors.map(error => enqueueSnackbar(error.message, { variant: "error" }))
        })
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={"1em"} bottom={"1em"}>
        <FormikField
          required
          disabled={!!selected}
          formik={formik}
          label={"Type"}
          field={"type"}
          type={"select"}
          options={Object.keys(VersionType).map((o) => ({ label: o, value: o }))}
        />
        <FormikField required formik={formik} label={"Version"} field={"semVer"} type={"text"}/>
        <Stack direction={"row"} spacing={"1em"} justifyContent={"right"}>
          {isModal && (<Button variant={"outlined"} onClick={() => onClose()}>Cancel</Button>)}
          <Button variant={"contained"} onClick={formik.submitForm}>{selected ? 'Update' : 'Create'}</Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default VersionForm;