import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import {
  Breadcrumbs,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { useApiGet } from "../../../api/ApiHooks";
import { Apis } from "../../../api/Config";
import { Attachment, Message, MessageType } from "../../../api/models/Email";
import MessageForm from "../components/MessageForm";
import BorderedSection from "../../../atoms/BorderedSection";
import ApizedAudit from "../../../components/audit/ApizedAudit";
import apiFor, { Service } from "../../../api/Api";
import { Close } from "@mui/icons-material";

const MessagePage = () => {
  const navigate = useNavigate();
  const { id, collection } = useParams<any>();
  const [ resendOpen, setResendOpen ] = useState(false);
  const [ address, setAddress ] = useState<string>();

  const { data: message, loading } = useApiGet<Message>(
    Apis.Email.Message,
    {
      collection: { id: collection }
    },
    {
      id,
      fields: [ '*', 'attachments.*' ]
    }
  )

  useEffect(() => {
    if (message) {
      setAddress(message.address);
    }
  }, [ message ]);

  return (
    <Stack spacing={"1em"} padding={"1em"}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href={`#/email/collections/${collection}/messages`}>
            Messages
          </Link>
          <Typography color="text.primary">Message <b>{message?.id}</b></Typography>
        </Breadcrumbs>
        <Stack direction={"row"} spacing={"1em"}>
          <Button
            variant={"outlined"}
            onClick={() => {
              setResendOpen(true);
            }}
          >
            Resend
          </Button>
          <ApizedAudit service={Service.Email} entity={"messages"} target={message?.id}/>
        </Stack>
      </Stack>
      {!loading && message && (
        <>
          <BorderedSection title={"Details"}>
            <MessageForm selected={message}/>
          </BorderedSection>
          <BorderedSection title={"Attachments"}>
            {(message?.attachments as Attachment[]).map((attachment) => (
              <Button
                key={attachment.filename}
                variant={"outlined"}
                href={`data:${attachment.mimeType};base64,${attachment.data}`}
                download={attachment.filename}
              >
                {attachment.filename}
              </Button>
            ))}
          </BorderedSection>
          <BorderedSection title={"Preview"}>
            <iframe
              title={"preview"}
              style={{ border: 'none', width: '100%' }}
              srcDoc={message.body}
              onLoad={(obj) => obj.currentTarget.style.height = obj.currentTarget.contentWindow?.document?.documentElement?.scrollHeight + 'px'}
            />
          </BorderedSection>
        </>
      )}
      <Dialog open={resendOpen}>
        <DialogTitle>
          <Stack direction={"row"} justifyContent={"space-between"}><span>Resend email</span>
            <IconButton onClick={() => setResendOpen(false)}><Close/></IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={"1em"} style={{ minWidth: "20em", paddingTop: "0.5em" }}>
            <TextField label={"Address"} value={address} onChange={(e) => {
              setAddress(e.target.value)
            }}/>
            <Stack direction={"row"} spacing={"1em"} justifyContent={"flex-end"}>
              <Button style={{ width: "8em" }} variant={"outlined"} onClick={() => setResendOpen(false)}>Cancel</Button>
              <Button
                style={{ width: "8em" }}
                variant={"contained"}
                onClick={() => {
                  apiFor(Apis.Email.Message, { collection }).create({
                    obj: {
                      address: address,
                      type: message.type,
                      subject: message.subject,
                      body: message.type === MessageType.RAW ? message.body : undefined,
                      template: message.template || undefined,
                      variables: message.variables,
                      attachments: message.attachments
                    }
                  }).then(() => {
                    navigate(`/email/collections/${collection}/messages`);
                  });
                }}
              >Send</Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </Stack>
  );
};

export default MessagePage;
