import ApizedListPage from "../../../components/ApizedListPage";
import { Target } from "../../../api/models/Webhooks";
import { IconButton, Stack } from "@mui/material";
import { Apis } from "../../../api/Config";
import React, { useState } from "react";
import { Operation } from "../../../api/Search";
import { GreenBadge, YellowBadge } from "../../../atoms/Badge";
import { Delete } from "@mui/icons-material";
import TargetForm from "../components/TargetForm";
import apiFor, { ApiError } from "../../../api/Api";
import { useSnackbar } from "notistack";

const TargetsPage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const targetApi = apiFor(Apis.Webhooks.Target);
  const [ refetchTrigger, setRefetchTrigger ] = useState(0)

  const searchConfig = [
    {
      id: 'name',
      label: 'Name',
      operations: [
        { label: 'is', value: Operation.Equals },
        { label: 'contains', value: Operation.Like },
      ],
      value: 'name',
      values: {
        label: 'name',
        query: {
          context: {},
          definition: Apis.Webhooks.Target
        },
        value: 'name'
      },
    },
    {
      id: 'active',
      label: 'Active',
      operations: [ { label: 'is', value: Operation.Equals } ],
      value: 'active',
      values: [
        { label: 'Yes', value: 'true' },
        { label: 'No', value: 'false' },
      ],
    },
  ];

  return (
    <Stack spacing={"1em"}>
      <ApizedListPage<Target>
        columns={[
          {
            label: 'Name',
            minWidth: 150,
            format: (item) => item.name,
          },
          {
            label: 'Topic',
            minWidth: 200,
            format: (item) => item.topic,
          },
          {
            label: 'Active',
            minWidth: 100,
            format: (item) =>
              item.active ? (
                <GreenBadge>Yes</GreenBadge>
              ) : (
                <YellowBadge>No</YellowBadge>
              ),
          },
          {
            label: 'URL',
            minWidth: 300,
            format: (item) => item.url,
          },
          {
            label: 'Actions',
            minWidth: 100,
            format: (item) => (
              <IconButton
                onClick={(e): void => {
                  e.stopPropagation();
                  if (window.confirm(`Are you sure you wish to delete target '${item.name}'?`)) {
                    targetApi.remove({ id: item.id! })
                      .then(() => {
                        setRefetchTrigger((new Date()).getTime());
                        enqueueSnackbar(`Target '${item.name}' deleted!`, { variant: "success" });
                      })
                      .catch((e: ApiError) => {
                        e.errors.map(error => enqueueSnackbar(error.message, { variant: "error" }))
                      });
                  }
                }}
              >
                <Delete/>
              </IconButton>
            ),
          },
        ]}
        context={{}}
        form={TargetForm}
        fields={[ '*' ]}
        query={Apis.Webhooks.Target}
        searchConfig={searchConfig}
        refetchTrigger={refetchTrigger}
      />
    </Stack>
  );
};

export default TargetsPage;
