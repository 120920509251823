import React from 'react';
import { Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import { SearchEntry } from "../../../components/search/Types";
import { Operation } from "../../../api/Search";
import { Apis } from "../../../api/Config";
import { Template } from "../../../api/models/Email";
import ApizedListPage from "../../../components/ApizedListPage";
import TemplateForm, { TemplateFormProps } from "../components/TemplateForm";

const TemplatesPage = () => {
  const { collection } = useParams<any>();

  const searchConfig: SearchEntry[] = [
    {
      id: 'name',
      label: "Name",
      operations: [
        { label: "contains", value: Operation.Like },
        { label: "is", value: Operation.Equals },
        { label: "is not", value: Operation.NotEquals },
      ],
      value: 'name',
      values: {
        label: 'name',
        value: 'name',
        query: {
          context: {},
          definition: Apis.Email.Template
        },
      }
    },
  ];

  return (
    <Stack spacing={"1em"}>
      <ApizedListPage<Template, TemplateFormProps>
        columns={[
          { label: 'Name', minWidth: 170, format: (value) => value.name },
        ]}
        fields={[ 'name', 'subject', 'body' ]}
        form={TemplateForm}
        formProps={{ collection }}
        context={{ collection }}
        query={Apis.Email.Template}
        searchConfig={searchConfig}
        dialogProps={{ fullWidth: true, maxWidth: "xl" }}
      />
    </Stack>
  );
};

export default TemplatesPage;
