import React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Box, Breadcrumbs, Button, Link, Stack, Typography } from "@mui/material";
import { useApiGet } from "../../../api/ApiHooks";
import { Apis } from "../../../api/Config";
import { Fare, Order, OrderItem } from "../../../api/models/Vivi";
import OrderForm from "../components/OrderForm";
import BorderedSection from "../../../atoms/BorderedSection";
import ApizedAudit from "../../../components/audit/ApizedAudit";
import { Service } from "../../../api/Api";

const OrderPage = () => {
  const { id } = useParams<any>();
  const navigate = useNavigate();
  const { data: order, loading } = useApiGet<Order>(
    Apis.Vivi.Order,
    { token: '8fb4fa31-1a78-4d25-82dc-a9dda48fa7d8' },
    {
      id,
      fields: [ '*', 'orderItems.fare.*', 'orderItems.tickets.*' ]
    }
  )

  return (
    <Stack spacing={"1em"}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="#/vivi/orders">
            Orders
          </Link>
          <Typography color="text.primary">Order <b>{order?.id}</b></Typography>
        </Breadcrumbs>
        <Stack direction={"row"} spacing={"1em"}>
          <Button variant={"contained"} onClick={() => navigate(`/vivi/profiles/${order.owner}`)}>Profile</Button>
          <ApizedAudit service={Service.Vivi} entity={"orders"} target={id}/>
        </Stack>
      </Stack>
      {!loading && order && (
        <>
          <BorderedSection title={"Details"}>
            <OrderForm selected={order}/>
          </BorderedSection>
          <BorderedSection title={"Items"}>
            {(order.orderItems as OrderItem[])?.map(oi =>
              <Stack direction={"row"} key={oi.id} spacing={"1em"}>
                <Box>1</Box>
                <Box>x</Box>
                <Box>{(oi.fare as Fare).name}</Box>
              </Stack>
            )}
          </BorderedSection>
        </>
      )}
    </Stack>
  );
};

export default OrderPage;
