import { Button, Stack, TextField } from "@mui/material";
import React from "react";
import { Ticket } from "../../../api/models/Vivi";
import { ApizedFormProps } from "../../../components/ApizedListPage";

const TicketForm = (
  {
    isModal,
    onClose = () => null,
    selected
  }: ApizedFormProps<Ticket>) => {
  return (
    <Stack spacing={"1em"} bottom={"1em"}>
      <TextField
        disabled
        fullWidth
        label="Id"
        name="id"
        type="text"
        autoComplete={"none"}
        value={selected?.id}
      />
      <Stack direction={"row"} spacing={"1em"} justifyContent={"right"}>
        {isModal && (<Button variant={"outlined"} onClick={() => onClose()}>Cancel</Button>)}
      </Stack>
    </Stack>
  );
};

export default TicketForm;