import { Application, MenuEntry } from "../../lib/Types";
import CollectionsPage from "./pages/CollectionsPage";
import MessagesPage from "./pages/MessagesPage";
import MessagePage from "./pages/MessagePage";
import TemplatesPage from "./pages/TemplatesPage";
import React from "react";
import { Apis } from "../../api/Config";
import apiFor from "../../api/Api";
import { Navigate } from "react-router-dom";

let dynamicMenus: MenuEntry[] = [];

const updateDynamicRoutes = (resolve: () => void, reject: () => void) => {
  apiFor(Apis.Email.Collection)
    .list({ fields: [ 'name', 'id' ] })
    .then((r) => {
      dynamicMenus = [];
      r.content.forEach((collection) => {
        dynamicMenus.push({
          path: `/collections/${collection.id}/messages`,
          description: `Messages (${collection.name})`,
        });
        dynamicMenus.push({
          path: `/collections/${collection.id}/templates`,
          description: `Templates (${collection.name})`,
        });
      });
      resolve();
    }).catch(() => {
    dynamicMenus = [];
    reject();
  });
}

const EmailApp: Application = {
  name: "Email",
  path: "email",
  menus: [
    {
      path: '/collections',
      description: "Collections",
    }
  ],
  routes: [
    {
      path: '/',
      element: <Navigate to={'/email/collections'} replace/>,
    },
    {
      path: '/collections',
      element: <CollectionsPage/>,
    },
    {
      path: '/collections/:collection/messages',
      element: <MessagesPage/>,
    },
    {
      path: '/collections/:collection/messages/:id',
      element: <MessagePage/>,
    },
    {
      path: '/collections/:collection/templates',
      element: <TemplatesPage/>,
    },
  ],
  extra: () => new Promise<Application>((resolve, reject) => updateDynamicRoutes(() => resolve({
    ...EmailApp,
    menus: EmailApp.menus.concat(dynamicMenus)
  }), reject))
};

export default EmailApp;