import React from 'react';
import { Stack } from "@mui/material";
import { SearchEntry } from "../../../components/search/Types";
import { Apis } from "../../../api/Config";
import { Alert } from "../../../api/models/Vivi";
import ApizedListPage from "../../../components/ApizedListPage";
import AlertForm from "../components/AlertForm";
import { convertTimeZone, toDateTimeString } from "../../../lib/DateTime";

const AlertsPage = () => {
  const searchConfig: SearchEntry[] = [];

  return (
    <Stack spacing={"1em"}>
      <ApizedListPage<Alert>
        columns={[
          {
            label: 'Start',
            minWidth: 170,
            format: (value) => value.start ? toDateTimeString(convertTimeZone({
              date: new Date(value.start),
              from: "UTC"
            })) : '-'
          },
          {
            label: 'Effect Start',
            minWidth: 170,
            format: (value) => value.effectStart ? toDateTimeString(convertTimeZone({
              date: new Date(value.effectStart),
              from: "UTC"
            })) : '-'
          },
          {
            label: 'End',
            minWidth: 170,
            format: (value) => value.end ? toDateTimeString(convertTimeZone({
              date: new Date(value.end),
              from: "UTC"
            })) : '-'
          },
          { label: 'Message', format: (value) => value.message },
        ]}
        fields={[ '*' ]}
        context={{ token: '8fb4fa31-1a78-4d25-82dc-a9dda48fa7d8' }}
        query={Apis.Vivi.Alert}
        form={AlertForm}
        searchConfig={searchConfig}
      />
    </Stack>
  );
};

export default AlertsPage;
