import React from 'react';
import { useParams } from "react-router-dom";
import { Breadcrumbs, Link, Stack, Typography } from "@mui/material";
import { useApiGet } from "../../../api/ApiHooks";
import { Apis } from "../../../api/Config";
import { Route } from "../../../api/models/Vivi";
import BorderedSection from "../../../atoms/BorderedSection";
import ScheduleForm from "../components/ScheduleForm";
import { Service } from "../../../api/Api";
import ApizedAudit from "../../../components/audit/ApizedAudit";

const SchedulePage = () => {
  const { id } = useParams<any>();
  const { data: route, loading } = useApiGet<Route>(
    Apis.Vivi.Route,
    { token: '8fb4fa31-1a78-4d25-82dc-a9dda48fa7d8' },
    {
      id,
    }
  )

  return (
    <Stack spacing={"1em"}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="#/vivi/schedules">
            Schedules
          </Link>
          <Typography color="text.primary">Ticket <b>{route?.id}</b></Typography>
        </Breadcrumbs>
        <ApizedAudit service={Service.Vivi} entity={"routes"} target={id}/>
      </Stack>
      {!loading && route && (
        <>
          <BorderedSection title={"Details"}>
            <ScheduleForm selected={route}/>
          </BorderedSection>
        </>
      )}
    </Stack>
  );
};

export default SchedulePage;
