import UsersPage from "./pages/UsersPage";
import UserPage from "./pages/UserPage";
import RolesPage from "./pages/RolesPage";
import RolePage from "./pages/RolePage";
import OauthsPage from "./pages/OauthsPage";
import React from "react";
import { Application } from "../../lib/Types";
import { Navigate } from "react-router-dom";

const AuthApp: Application = {
  name: "Auth",
  path: "auth",
  menus: [
    {
      path: '/users',
      description: "Users",
    },
    {
      path: '/roles',
      description: "Roles",
    },
    {
      path: '/oauths',
      description: "Oauths",
    },
  ],
  routes: [
    {
      path: '/',
      element: <Navigate to={'/auth/users'} replace/>,
    },
    {
      path: '/users',
      element: <UsersPage/>,
    },
    {
      path: '/users/:id',
      element: <UserPage/>,
    },
    {
      path: '/roles',
      element: <RolesPage/>,
    },
    {
      path: '/roles/:id',
      element: <RolePage/>,
    },
    {
      path: '/oauths',
      element: <OauthsPage/>,
    },
  ]
}

export default AuthApp;