import React from "react";
import { Application } from "../../lib/Types";
import JourneysPage from "./pages/JourneysPage";
import JourneyPage from "./pages/JourneyPage";
import OrderPage from "./pages/OrderPage";
import OrdersPage from "./pages/OrdersPage";
import AlertsPage from "./pages/AlertsPage";
import TripsPage from "./pages/TripsPage";
import TripPage from "./pages/TripPage";
import TicketsPage from "./pages/TicketsPage";
import TicketPage from "./pages/TicketPage";
import ProfilesPage from "./pages/ProfilesPage";
import { Navigate } from "react-router-dom";
import VersionsPage from "./pages/VersionsPage";
import SchedulesPage from "./pages/SchedulesPage";
import SchedulePage from "./pages/SchedulePage";
import ProfilePage from "./pages/ProfilePage";
import FeatureFlagsPage from "./pages/FeatureFlagsPage";

const ViviApp: Application = {
  name: "Vivi",
  path: "vivi",
  menus: [
    {
      path: '/journeys',
      description: "Journeys",
    },
    {
      path: '/trips',
      description: "Trips",
    },
    {
      path: '/orders',
      description: "Orders",
    },
    {
      path: '/tickets',
      description: "Tickets",
    },
    {
      path: '/profiles',
      description: "Profiles",
    },
    {
      path: '/schedules',
      description: "Schedules",
    },
    {
      path: '/alerts',
      description: "Alerts",
    },
    {
      path: '/versions',
      description: "Versions",
    },
    {
      path: '/features',
      description: "Feature Flags",
    }
  ],
  routes: [
    {
      path: '/',
      element: <Navigate to={'/vivi/orders'} replace/>,
    },
    {
      path: '/journeys',
      element: <JourneysPage/>,
    },
    {
      path: '/journeys/:id',
      element: <JourneyPage/>,
    },
    {
      path: '/trips',
      element: <TripsPage/>,
    },
    {
      path: '/trips/:id',
      element: <TripPage/>,
    },
    {
      path: '/tickets',
      element: <TicketsPage/>,
    },
    {
      path: '/tickets/:id',
      element: <TicketPage/>,
    },
    {
      path: '/profiles',
      element: <ProfilesPage/>,
    },
    {
      path: '/profiles/:id',
      element: <ProfilePage/>,
    },
    {
      path: '/schedules',
      element: <SchedulesPage/>,
    },
    {
      path: '/schedules/:id',
      element: <SchedulePage/>,
    },
    {
      path: '/orders',
      element: <OrdersPage/>,
    },
    {
      path: '/orders/:id',
      element: <OrderPage/>,
    },
    {
      path: '/alerts',
      element: <AlertsPage/>,
    },
    {
      path: '/versions',
      element: <VersionsPage/>,
    },
    {
      path: '/features',
      element: <FeatureFlagsPage/>,
    },
  ]
}

export default ViviApp;