import React from 'react';

import ApizedListPage from "../../../components/ApizedListPage";
import { Execution, Message, Source, Target } from "../../../api/models/Webhooks";
import { SearchEntry } from "../../../components/search/Types";
import { Link, Stack } from "@mui/material";
import MessageForm from "../components/MessageForm";
import { Apis } from "../../../api/Config";
import { convertTimeZone, toDateTimeString } from "../../../lib/DateTime";
import { Operation } from "../../../api/Search";
import { Async, AsyncProps } from "react-async";
import { Profile } from "../../../api/models/Vivi";
import apiFor from "../../../api/Api";

const MessagesPage = () => {
  const searchConfig = [
    {
      id: 'topic',
      label: 'Topic',
      operations: [
        { label: 'is', value: Operation.Equals },
        { label: 'contains', value: Operation.Like },
      ],
      value: 'topic',
      values: {
        create: true,
        label: 'topic',
        query: {
          context: {},
          definition: Apis.Webhooks.Message
        },
        value: 'topic',
      },
    },
    {
      id: 'target',
      label: 'Target',
      operations: [
        { label: 'is', value: Operation.Equals },
        { label: 'is not', value: Operation.NotEquals },
      ],
      value: 'executions.target.id',
      values: {
        extra: (term, input) => term.op === Operation.NotEquals && '[empty]'.indexOf(input.toLowerCase()) !== -1 ? [ {
          label: '[EMPTY]',
          value: null
        } ] : [],
        query: {
          context: {},
          definition: Apis.Webhooks.Target
        }
      },
    },
    {
      id: 'source',
      label: 'Source',
      operations: [
        { label: 'is', value: Operation.Equals },
      ],
      value: 'payload',
      values: {
        query: {
          context: {},
          definition: Apis.Webhooks.Source
        },
        transform: (value: Source) => ({ label: value.name, value: `{"header":{"webhooks-source":"${value.id}"}}` }),
        // todo
        // transform: {
        //   forward: (value: Source) => ({ label: value.name, value: `{"header":{"webhooks-source":"${value.id}"}}` }),
        //   reverse: (value: string) => ({
        //     label: JSON.parse(value).header['webhooks-source'],
        //     value: value
        //   })
        // }
      },
    },
    {
      id: 'train_id',
      label: 'Train Id',
      value: "payload",
      operations: [
        { label: 'is', value: Operation.Equals },
      ],
      values: (text) => {
        if (text) {
          try {
            text = JSON.parse(text).payload.id || text;
          } catch (e) {
          }
          return [ { label: text, value: `{"payload":{"id":"${text}"}}` } ]
        }
        return [];
      }
    },
  ] as SearchEntry[];

  const sourceApi = apiFor(Apis.Webhooks.Source);
  const resolveSource = ({ id }: AsyncProps<Source>) => {
    return new Promise<Profile>((resolve) => {
      sourceApi.get({ id: id, fields: [ 'name' ] }).then(resolve);
    });
  };


  return (
    <Stack spacing={"1em"}>
      <ApizedListPage<Message>
        columns={[
          {
            label: 'Date',
            minWidth: 170,
            format: (item) =>
              toDateTimeString(convertTimeZone({ date: new Date(item.createdAt!), from: 'UTC' })),
          },
          {
            label: 'Topic',
            minWidth: 170,
            format: (item) => item.topic,
          },
          {
            label: 'Created By',
            minWidth: 170,
            format: (item) => item.createdBy,
          },
          {
            label: 'Source',
            minWidth: 170,
            format: (item) =>
              (item.payload?.header as any)?.['webhooks-source']
                ? <Async promiseFn={resolveSource} id={(item.payload?.header as any)?.['webhooks-source']!}>
                  {({ data }) => data
                    ? <Link underline="hover" href={`#/webhooks/sources?q=name%3D${data.name}`}>
                      {data?.name}
                    </Link>
                    : <div className={"shimmer"} style={{ width: '100%', height: '1.25em' }}/>}
                </Async>
                : '',
          },
          {
            label: 'Executions',
            minWidth: 340,
            format: (item) => (
              <Stack spacing="0.25em">
                {item.executions?.length === 0 && 'N/A'}
                {(item.executions as Execution[])
                  ?.filter((e) => e?.retryOf === null)
                  ?.map((e) => (
                    <Link key={e?.id} underline="hover" href={`#/webhooks/executions/${e?.id}`}>
                      {`${(e?.target as Target)?.name}<${e?.latestStatus}>`}
                    </Link>
                  ))}
              </Stack>
            ),
          },
        ]}
        context={{}}
        form={MessageForm}
        fields={[ '*', 'executions.retryOf', 'executions.latestStatus', 'executions.target.name' ]}
        query={Apis.Webhooks.Message}
        searchConfig={searchConfig}
      />
    </Stack>
  );
};

export default MessagesPage;
