/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Box, Container, Stack, Tab, Tabs } from "@mui/material";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Application, MenuEntry, MenuState } from "../lib/Types";
import SocialLogin from "./SocialLogin";

const Main = ({
  applications,
  menuState,
  onChange
}: {
  applications: Application[],
  menuState: MenuState,
  onChange: (state: MenuState) => void
}) => {
  const defaultApp = applications[0]?.path;
  const defaultRoute = applications[0]?.routes[0]?.path;
  const navigate = useNavigate();

  const location = useLocation();
  useEffect(() => {
    let parts = location.pathname.split('/');
    let appPath = parts.splice(0, 2)[1];
    let menuPath = '/' + parts.join('/');

    const app = applications.find((a) => a.path === appPath)
    let menu = app?.menus?.findIndex((m) => m.path === menuPath)

    if (menu === -1) {
      menu = (([] as MenuEntry[]).concat(app?.menus || [])).reverse()
        ?.findIndex((m, idx) => menuPath.startsWith(m.path));
      if (menu !== -1) {
        menu = Math.abs(menu - app!.menus!.length + 1);
      }
    }
    if (app && typeof menu !== 'undefined' && menu > -1) {
      onChange({ application: app, menu });
    }
  }, [ location, applications ]);

  return (
    <Box paddingBottom={"2em"}>
      <Container maxWidth={"xl"}>
        {defaultApp &&
          <Routes>
            <Route path={"/auth/login/:slug"} element={<SocialLogin/>}/>
            {applications.map((application) =>
              application.routes.map((route) => (
                <Route
                  path={`/${application.path}${route.path}`}
                  element={
                    <Stack>
                      <Box sx={{ marginBottom: '1em' }}>
                        <Tabs
                          variant={"scrollable"}
                          style={{ maxWidth: '100%' }}
                          value={menuState.menu}
                          onChange={(_, idx) => {
                            onChange({ ...menuState, menu: idx });
                            navigate(`/${application.path}${application.menus[idx].path}`)
                          }}
                        >
                          {application.menus.map((m) =>
                            <Tab key={m.path} label={m.description}/>
                          )}
                        </Tabs>
                      </Box>
                      {route.element}
                    </Stack>
                  }/>
              ))
            )}
            <Route path="*" element={<Navigate to={`/${defaultApp}${defaultRoute}`} replace/>}/>
          </Routes>
        }
      </Container>
    </Box>);
};

export default Main;
