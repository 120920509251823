import { Button, FormControlLabel, FormGroup, Stack, Switch } from "@mui/material";
import React from "react";
import apiFor, { ApiError } from "../../../api/Api";
import { Apis } from "../../../api/Config";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { ApizedFormProps } from "../../../components/ApizedListPage";
import FormikField from "../../../components/FormikField";
import { Application, Configuration, NotificationType } from "../../../api/models/Notification";
import BorderedSection from "../../../atoms/BorderedSection";

type Config = {
  active: boolean
  certificate?: string
  privateKey?: string
  token?: string
} & Configuration;

const ApplicationForm = (
  {
    isModal = false,
    onClose = () => null,
    selected
  }: ApizedFormProps<Application>
) => {
  const { enqueueSnackbar } = useSnackbar();
  const api = apiFor(Apis.Notification.Application, {});
  const configurations: Config[] = [];

  const configs = (selected?.configurations as Configuration[]) || [];
  Object.entries(NotificationType).forEach(([ name, type ]) => {
    let find = configs.find(c => c.type === type);
    if (!find) {
      configurations.push({ active: false, type });
    } else {
      configurations.push({
        active: true,
        id: find.id,
        type: find.type,
        certificate: find.credentials?.certificate,
        privateKey: find.credentials?.privateKey,
        token: find.credentials?.token
      });
    }
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: selected?.id || undefined,
      name: selected?.name || '',
      slug: selected?.slug || '',
      configurations
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().min(3).required(),
      slug: Yup.string().min(3).lowercase().required(),
      // config: Yup.array()
      //   .of(Yup.object().shape({
      //       certificate: Yup.string().required(),
      //       privateKey: Yup.string().required(),
      //       token: Yup.string().required(),
      //     })
      //   ).compact((v) => !v.checked)
    }),
    onSubmit: (values) => {
      const configs = formik.values.configurations.filter(c => c.active).map((c => {
        const conf: Configuration = { id: c.id, type: c.type };
        switch (c.type) {
          case NotificationType.APNS_SANDBOX:
          case NotificationType.APNS:
            conf.credentials = {
              certificate: c.certificate,
              privateKey: c.privateKey
            } as { [key: string]: string };
            break;
          case NotificationType.GCM:
            conf.credentials = {
              token: c.token
            } as { [key: string]: string };
        }
        return conf;
      }));

      if (selected) {
        api.update({
          id: selected.id!,
          obj: { ...values, configurations: configs },
        }).then((u) => {
          onClose(u);
          enqueueSnackbar(`Application '${u.name}' updated!`, { variant: "success" });
        }).catch((e: ApiError) => {
          e.errors.map(error => enqueueSnackbar(error.message, { variant: "error" }))
        })
      } else {
        api.create({
          obj: { ...values, configurations: configs },
        }).then((u) => {
          onClose(u);
          enqueueSnackbar(`Application '${u.name}' created!`, { variant: "success" });
        }).catch((e: ApiError) => {
          e.errors.map(error => enqueueSnackbar(error.message, { variant: "error" }))
        })
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={"1em"} bottom={"1em"}>
        <FormikField required formik={formik} label={"Name"} field={"name"} type={"text"}/>
        <FormikField required formik={formik} label={"Slug"} field={"slug"} type={"text"}/>
        <Stack direction={"row"} spacing={"1em"}>
          {configurations.map((config, idx) => (
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    name={`configurations[${idx}].active`}
                    checked={formik.values.configurations[idx].active}
                    onChange={formik.handleChange}
                  />
                }
                label={config.type}
                labelPlacement="start"
              />
            </FormGroup>
          ))}
        </Stack>
        {configurations.map((config, idx) => (
          <>
            {formik.values.configurations[idx].active && (
              <BorderedSection title={config.type} key={idx}>
                <Stack spacing={'1em'}>
                  {formik.values.configurations[idx].type !== NotificationType.GCM
                    ? <>
                      <FormikField
                        required
                        multiline
                        formik={formik}
                        label={"Certificate"}
                        field={`configurations[${idx}].certificate`}
                        type={"text"}
                        maxRows={5}
                      />
                      <FormikField
                        required
                        multiline
                        formik={formik}
                        label={"Private Key"}
                        field={`configurations[${idx}].privateKey`}
                        type={"text"}
                        maxRows={5}
                      />
                    </>
                    : <FormikField
                      required
                      multiline
                      formik={formik}
                      label={"Token"}
                      field={`configurations[${idx}].token`}
                      type={"text"}
                      maxRows={5}
                    />
                  }
                </Stack>
              </BorderedSection>
            )}
          </>
        ))}
        <Stack direction={"row"} spacing={"1em"} justifyContent={"right"}>
          {isModal && (<Button variant={"outlined"} onClick={() => onClose()}>Cancel</Button>)}
          <Button variant={"contained"} onClick={formik.submitForm}>{selected ? 'Update' : 'Create'}</Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default ApplicationForm;