import React from 'react';
import { Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { SearchEntry } from "../../../components/search/Types";
import { Operation } from "../../../api/Search";
import { Apis } from "../../../api/Config";
import { Location, Profile, Source, Trip } from "../../../api/models/Vivi";
import ApizedListPage from "../../../components/ApizedListPage";
import { Async, AsyncProps } from "react-async";
import apiFor from "../../../api/Api";

const TripsPage = () => {
  const navigate = useNavigate();

  const userCache: { [key: string]: { resolved?: Profile, cbs: [ (profile: Profile) => void ] } } = {};
  const profileApi = apiFor(Apis.Vivi.Profile, { token: '8fb4fa31-1a78-4d25-82dc-a9dda48fa7d8' });
  const resolveProfile = ({ owner }: AsyncProps<Profile>) => {
    return new Promise<Profile>((resolve) => {
      if (!userCache[owner]) {
        userCache[owner] = { cbs: [ resolve ] };
        profileApi.get({ id: owner, fields: [ 'name', 'email' ] }).then((p) => {
          userCache[owner].resolved = p;
          userCache[owner].cbs.forEach((r) => r(p));
        })
      } else if (userCache[owner].resolved) {
        resolve(userCache[owner].resolved!);
      } else {
        userCache[owner].cbs.push(resolve);
      }
    });
  };

  const searchConfig: SearchEntry[] = [
    {
      id: 'user',
      label: "User",
      operations: [
        { label: "is", value: Operation.Equals },
        { label: "is not", value: Operation.NotEquals },
      ],
      value: 'owner',
      values: {
        label: 'name',
        value: 'id',
        query: {
          context: { token: '8fb4fa31-1a78-4d25-82dc-a9dda48fa7d8' },
          definition: Apis.Vivi.Profile
        }
      }
    },
    {
      id: 'source',
      label: "Source",
      operations: [
        { label: "is", value: Operation.Equals },
        { label: "is not", value: Operation.NotEquals },
      ],
      value: 'journey.source',
      values: Object.keys(Source).map((v) => ({ label: v, value: v }))
    },
    {
      id: 'follow',
      label: "Follow",
      operations: [
        { label: "is", value: Operation.Equals },
        { label: "is not", value: Operation.NotEquals },
      ],
      value: 'follow',
      values: [
        { label: 'Yes', value: 'true' },
        { label: 'No', value: 'false' }
      ]
    },
    {
      id: 'confidence',
      label: "Confidence",
      operations: [
        { label: "is", value: Operation.Equals },
        { label: "is not", value: Operation.NotEquals },
        { label: "more than", value: Operation.GreaterThan },
        { label: "less than", value: Operation.LessThan },
      ],
      value: 'journey.confidence'
    },
  ];

  return (
    <Stack spacing={"1em"}>
      <ApizedListPage<Trip>
        columns={[
          {
            label: 'Owner',
            minWidth: 170,
            format: (value) =>
              <Async promiseFn={resolveProfile} owner={value.owner!}>
                {({ data }) => data ? `${data?.name} (${data.email})` :
                  <div className={"shimmer"} style={{ width: '100%', height: '1.25em' }}/>}
              </Async>
          },
          {
            label: 'From',
            minWidth: 170,
            format: (value) => {
              const from = value.from as Location;
              return `${from.name} (${from?.address})`
            }
          },
          {
            label: 'To',
            minWidth: 170,
            format: (value) => {
              let to = value.to as Location;
              return `${to?.name} (${to?.address})`
            }
          },
          {
            label: 'Follow',
            minWidth: 60,
            format: (value) => value.follow ? 'Yes' : 'No'
          },
          {
            label: 'Source',
            minWidth: 70,
            format: (value) => value.source
          },
        ]}
        fields={[ '*', 'from.*', 'to.*' ]}
        context={{ token: '8fb4fa31-1a78-4d25-82dc-a9dda48fa7d8' }}
        query={Apis.Vivi.Trip}
        onRowClick={(collection) => navigate(`/vivi/trips/${collection.id}`)}
        searchConfig={searchConfig}
      />
    </Stack>
  );
};

export default TripsPage;
