import React from 'react';
import { Box, Breadcrumbs, capitalize, Stack, Typography } from "@mui/material";
import { SearchEntry } from "../../../components/search/Types";
import { Apis } from "../../../api/Config";
import { Location, Profile, Route, Schedule, TravelDirection } from "../../../api/models/Vivi";
import ApizedListPage from "../../../components/ApizedListPage";
import { Async, AsyncProps } from "react-async";
import apiFor from "../../../api/Api";
import { useNavigate } from "react-router-dom";
import WeekDay from "../components/WeekDay";
import { Operation } from "../../../api/Search";

const SchedulesPage = () => {
  const navigate = useNavigate();

  const searchConfig: SearchEntry[] = [
    {
      id: 'deleted', values: [ { label: 'Yes', value: 'true' }, { label: 'No', value: 'false' } ],
      label: 'Deleted',
      operations: [ { label: 'is', value: Operation.Equals }, ],
      value: 'deleted'
    }
  ];

  const userCache: { [key: string]: { resolved?: Profile, cbs: [ (profile: Profile) => void ] } } = {};
  const profileApi = apiFor(Apis.Vivi.Profile, { token: '8fb4fa31-1a78-4d25-82dc-a9dda48fa7d8' });
  const resolveProfile = ({ owner }: AsyncProps<Profile>) => {
    console.log(owner)
    return new Promise<Profile>((resolve) => {
      if (!userCache[owner]) {
        userCache[owner] = { cbs: [ resolve ] };
        profileApi.get({ id: owner, fields: [ 'name', 'email' ] }).then((p) => {
          userCache[owner].resolved = p;
          userCache[owner].cbs.forEach((r) => r(p));
        })
      } else if (userCache[owner].resolved) {
        resolve(userCache[owner].resolved!);
      } else {
        userCache[owner].cbs.push(resolve);
      }
    });
  };

  return (
    <Stack spacing={"1em"}>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary">Schedules</Typography>
      </Breadcrumbs>
      <ApizedListPage<Route>
        columns={[
          {
            label: 'Owner',
            minWidth: 100,
            format: (value) => {
              console.log(value)
              return <Async promiseFn={resolveProfile} owner={value.owner!}>
                {({ data }) => data
                  ? <Stack><Box>{data?.name}</Box><Box>({data.email})</Box></Stack>
                  : <div className={"shimmer"} style={{ width: '100%', height: '1.25em' }}/>}
              </Async>
            }
          },
          {
            label: 'Deleted',
            minWidth: 10,
            format: (value) => value.deleted ? 'Yes' : 'No'
          },
          {
            label: 'Route',
            minWidth: 150,
            format: (value) => `${(value.from as Location).name} -> ${(value.to as Location).name}`
          },
          {
            label: 'Times', minWidth: 150, format: (value) => {
              const outbound = (value.schedules as Schedule[]).find((s) => s.direction === TravelDirection.OUTBOUND);
              const inbound = (value.schedules as Schedule[]).find((s) => s.direction === TravelDirection.INBOUND);
              return (
                <Stack spacing={"0.25em"}>
                  <Box
                    style={{ textWrap: "nowrap" }}>Outbound: {capitalize(outbound?.mode?.toLowerCase() || '')} {outbound?.time}</Box>
                  <Box
                    style={{ textWrap: "nowrap" }}>Inbound: {capitalize(inbound?.mode?.toLowerCase() || '')} {inbound?.time}</Box>
                </Stack>
              )
            }
          },
          {
            label: 'Days', minWidth: 100, format: (value) => {
              const values = (value.schedules as Schedule[])?.[0]?.values;
              return <Stack direction={"row"} spacing={"0.25em"}>
                <WeekDay className={values?.find((v) => v === 1) ? "active" : ""}><span>M</span></WeekDay>
                <WeekDay className={values?.find((v) => v === 2) ? "active" : ""}><span>T</span></WeekDay>
                <WeekDay className={values?.find((v) => v === 3) ? "active" : ""}><span>W</span></WeekDay>
                <WeekDay className={values?.find((v) => v === 4) ? "active" : ""}><span>T</span></WeekDay>
                <WeekDay className={values?.find((v) => v === 5) ? "active" : ""}><span>F</span></WeekDay>
                <WeekDay className={values?.find((v) => v === 6) ? "active" : ""}><span>S</span></WeekDay>
                <WeekDay className={values?.find((v) => v === 7) ? "active" : ""}><span>S</span></WeekDay>
              </Stack>
            }
          }
        ]}
        fields={[ 'owner', 'from.name', 'to.name', 'schedules.*' ]}
        onRowClick={(collection) => navigate(`/vivi/schedules/${collection.id}`)}
        context={{ token: '8fb4fa31-1a78-4d25-82dc-a9dda48fa7d8' }}
        query={Apis.Vivi.Route}
        searchConfig={searchConfig}
      />
    </Stack>
  );
};

export default SchedulesPage;
