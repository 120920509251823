import { useFormik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { Message } from "../../../api/models/Webhooks";
import { Button, Stack } from "@mui/material";
import FormikField from "../../../components/FormikField";
import apiFor, { ApiError } from "../../../api/Api";
import { useSnackbar } from "notistack";
import { Apis } from "../../../api/Config";
import { ApizedFormProps } from "../../../components/ApizedListPage";

const MessageForm = ({
  isModal,
  onClose = () => null,
  selected
}: ApizedFormProps<Message>) => {
  const { enqueueSnackbar } = useSnackbar();
  const api = apiFor(Apis.Webhooks.Message);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      topic: selected?.topic || '',
      payload: selected?.payload
        ? JSON.stringify(selected?.payload, null, 2)
        : '',
    },
    validationSchema: Yup.object().shape({
      payload: Yup.string()
        .required()
        .test({
          message: 'Must be valid JSON',
          // eslint-disable-next-line object-shorthand,func-names
          test: function (payload) {
            try {
              JSON.parse(payload || '');
              return true;
            } catch (e) {
              return false;
            }
          },
        }),
      topic: Yup.string()
        .required()
        .min(3)
        .max(255),
    }),
    onSubmit: (values) => {
      if (!selected) {
        api.create({
          obj: {
            ...values,
            payload: JSON.parse(values.payload),
          },
        }).then((u) => {
          onClose(u);
          enqueueSnackbar(`Message '${u.id}' created!`, { variant: "success" });
        }).catch((e: ApiError) => {
          e.errors.map(error => enqueueSnackbar(error.message, { variant: "error" }))
        })
      }
    }
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={"1em"} bottom={"1em"}>
        <FormikField
          required
          disabled={!!selected}
          formik={formik}
          label={"Topic"}
          field={"topic"}
          type={"text"}
        />
        <FormikField
          required
          multiline
          disabled={!!selected}
          formik={formik}
          label={"Payload"}
          field={"payload"}
          minRows={3}
          maxRows={20}
          type={"text"}
        />
        <Stack direction={"row"} spacing={"1em"} justifyContent={"right"}>
          {isModal && (<Button variant={"outlined"} onClick={() => onClose()}>Cancel</Button>)}
          {!selected && <Button variant={"contained"} onClick={formik.submitForm}>Create</Button>}
        </Stack>
      </Stack>
    </form>
  );
};

export default MessageForm;
