import { Model, UUID } from "./Base";

export enum NotificationType {
  APNS_SANDBOX = "APNS_SANDBOX",
  APNS = "APNS",
  GCM = "GCM"
}

export enum MessageType {
  ALERT = 'ALERT',
  BACKGROUND = 'BACKGROUND',
  PASS = 'PASS'
}

export type Configuration = {
  type?: NotificationType
  credentials?: { [key: string]: string }
} & Model

export type Application = {
  name?: string
  slug?: string
  configurations?: (UUID | Configuration)[];
} & Model

export type Device = {
  enabled?: boolean
  description?: string
  user?: UUID
  token?: string
  type?: NotificationType
} & Model

export type Message = {
  createdAt?: string
  user?: UUID
  type?: MessageType
  title?: string
  body?: string
  data?: { [key: string]: unknown }
  notifications?: (UUID | Notification)[]
} & Model

export type Notification = {
  device?: UUID | Device
  payload?: { [key: string]: unknown }
} & Model