import { Button, Stack } from "@mui/material";
import React from "react";
import apiFor, { ApiError } from "../../../api/Api";
import { Apis } from "../../../api/Config";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { Message, MessageType } from "../../../api/models/Email";
import FormikField from "../../../components/FormikField";
import { ApizedFormProps } from "../../../components/ApizedListPage";
import { useParams } from "react-router-dom";

const MessageForm = (
  {
    isModal,
    onClose = () => null,
    selected
  }: ApizedFormProps<Message>
) => {
  const { enqueueSnackbar } = useSnackbar();
  const { collection } = useParams();
  const api = apiFor(Apis.Email.Message, { collection });
  const formik = useFormik({
    initialValues: {
      id: selected?.id || undefined,
      address: selected?.address || '',
      subject: selected?.subject || '',
      body: selected?.body || '',
      type: selected?.type || MessageType.RAW,
      template: selected?.template,
      variables: selected?.variables ? JSON.stringify(selected?.variables, null, 2) : '{}'
    },
    validationSchema: Yup.object().shape({
      address: Yup.string().email().required(),
      subject: Yup.string().test({
        exclusive: false,
        test: (_value, validationContext) => {
          const parent = validationContext.parent
          if (parent?.type === MessageType.RAW && !_value) {
            return validationContext?.createError({
              message: "Subject is required",
              path: validationContext?.path,
            })
          }
          return true
        }
      }),
      body: Yup.string().test({
        exclusive: false,
        test: (_value, validationContext) => {
          const parent = validationContext.parent
          if (parent?.type === MessageType.RAW && !_value) {
            return validationContext?.createError({
              message: "Body is required",
              path: validationContext?.path,
            })
          }
          return true
        }
      }),
      type: Yup.string().required(),
      template: Yup.string().test({
        exclusive: false,
        test: (_value, validationContext) => {
          const parent = validationContext.parent
          if (parent?.type === MessageType.TEMPLATE && !_value) {
            return validationContext?.createError({
              message: "Template is required",
              path: validationContext?.path,
            })
          }
          return true
        }
      })
    }),
    onSubmit: (values) => {
      if (!selected) {
        api.create({
          obj: {
            ...values,
            variables: JSON.parse(values.variables),
            body: values.type === MessageType.RAW ? values.body : undefined
          },
        }).then((u) => {
          onClose(u);
          enqueueSnackbar(`Message '${u.id}' created!`, { variant: "success" });
        }).catch((e: ApiError) => {
          e.errors.map(error => enqueueSnackbar(error.message, { variant: "error" }))
        })
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={"1em"} bottom={"1em"}>
        <FormikField
          required
          disabled={!!selected}
          formik={formik}
          label={"Address"}
          field={"address"}
          type={"text"}
        />
        <FormikField
          required
          disabled={!!selected}
          formik={formik}
          label={"Type"}
          field={"type"}
          type={"select"}
          options={Object.keys(MessageType).map((o) => ({ label: o, value: o }))}
        />
        {formik.values.type === MessageType.TEMPLATE && (
          <>
            <FormikField
              required
              disabled={!!selected}
              formik={formik}
              label={"Template"}
              field={"template"}
              type={"reference"}
              async={{
                label: 'name',
                value: 'id',
                query: {
                  context: { collection },
                  definition: Apis.Email.Template
                }
              }}
            />
            <FormikField
              required
              multiline
              disabled={!!selected}
              formik={formik}
              label={"Variables"}
              field={"variables"}
              type={"text"}
            />
          </>
        )}
        {(!isModal || formik.values.type === MessageType.RAW) && (
          <>
            <FormikField
              required
              disabled={!!selected}
              formik={formik}
              label={"Subject"}
              field={"subject"}
              type={"text"}
            />
            <FormikField
              required
              disabled={!!selected}
              formik={formik}
              label={"Body"}
              field={"body"}
              type={"text"}
              multiline
            />
          </>
        )}
        <Stack direction={"row"} spacing={"1em"} justifyContent={"right"}>
          {isModal && (<Button variant={"outlined"} onClick={() => onClose()}>Cancel</Button>)}
          {!selected && <Button variant={"contained"} onClick={formik.submitForm}>Create</Button>}
        </Stack>
      </Stack>
    </form>
  );
};

export default MessageForm;