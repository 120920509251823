import { Button, Stack, TextField } from "@mui/material";
import React from "react";
import { Trip } from "../../../api/models/Vivi";
import { ApizedFormProps } from "../../../components/ApizedListPage";

const TripForm = (
  {
    isModal,
    onClose = () => null,
    selected
  }: ApizedFormProps<Trip>) => {
  return (
    <Stack spacing={"1em"} bottom={"1em"}>
      <TextField
        disabled
        fullWidth
        label="Owner"
        name="owner"
        type="text"
        autoComplete={"none"}
        value={selected?.owner}
      />
      <Stack direction={"row"} spacing={"1em"} justifyContent={"right"}>
        {isModal && (<Button variant={"outlined"} onClick={() => onClose()}>Cancel</Button>)}
      </Stack>
    </Stack>
  );
};

export default TripForm;