import React, { useState } from 'react';
import { Button, Stack } from "@mui/material";
import { SearchEntry } from "../../../components/search/Types";
import { Operation } from "../../../api/Search";
import { Apis } from "../../../api/Config";
import { Collection } from "../../../api/models/Email";
import ApizedListPage from "../../../components/ApizedListPage";
import CollectionForm from "../components/CollectionForm";
import apiFor, { ApiError } from "../../../api/Api";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

const CollectionsPage = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const collectionApi = apiFor(Apis.Email.Collection);
  const [ refetchTrigger, setRefetchTrigger ] = useState(0)

  const searchConfig: SearchEntry[] = [
    {
      id: 'name',
      label: "Name",
      operations: [
        { label: "contains", value: Operation.Like },
        { label: "is", value: Operation.Equals },
        { label: "is not", value: Operation.NotEquals },
      ],
      value: 'name',
      values: {
        label: 'name',
        value: 'name',
        query: {
          context: {},
          definition: Apis.Email.Collection
        },
      }
    },
  ];

  return (
    <Stack spacing={"1em"}>
      <ApizedListPage<Collection>
        columns={[
          { label: 'Name', minWidth: 200, format: (value) => value.name },
          { label: 'Sender', minWidth: 200, format: (value) => value.sender },
          { label: 'Variables', minWidth: 200, format: (value) => JSON.stringify(value.variables) },
          {
            label: 'Actions', minWidth: 20, format: (value) =>
              <Stack direction="row" spacing="1em" justifyContent={"right"}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/email/collections/${value.id}/messages`);
                  }}>
                  messages
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/email/collections/${value.id}/templates`);
                  }}>
                  templates
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (window.confirm(`Are you sure you wish to delete collection '${value.name}'?`)) {
                      collectionApi.remove({ id: value.id! })
                        .then(() => {
                          setRefetchTrigger((new Date()).getTime());
                          enqueueSnackbar(`Collection '${value.name}' deleted!`, { variant: "success" });
                        })
                        .catch((e: ApiError) => {
                          e.errors.map(error => enqueueSnackbar(error.message, { variant: "error" }))
                        });
                    }
                  }}>
                  delete
                </Button>
              </Stack>
          },
        ]}
        fields={[ 'name', 'sender', 'variables' ]}
        context={{}}
        query={Apis.Email.Collection}
        form={CollectionForm}
        searchConfig={searchConfig}
        refetchTrigger={refetchTrigger}
      />
    </Stack>
  );
};

export default CollectionsPage;
