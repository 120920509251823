import React from 'react';
import { Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { SearchEntry } from "../../../components/search/Types";
import { Operation } from "../../../api/Search";
import { Apis } from "../../../api/Config";
import { Journey, Location, Source, Profile } from "../../../api/models/Vivi";
import ApizedListPage from "../../../components/ApizedListPage";
import { Async, AsyncProps } from "react-async";
import apiFor from "../../../api/Api";
import { convertTimeZone, toDateTimeString } from "../../../lib/DateTime";

const JourneysPage = () => {
  const navigate = useNavigate();

  const userCache: { [key: string]: { resolved?: Profile, cbs: [ (profile: Profile) => void ] } } = {};
  const profileApi = apiFor(Apis.Vivi.Profile, { token: '8fb4fa31-1a78-4d25-82dc-a9dda48fa7d8' });
  const resolveProfile = ({ owner }: AsyncProps<Profile>) => {
    return new Promise<Profile>((resolve) => {
      if (!userCache[owner]) {
        userCache[owner] = { cbs: [ resolve ] };
        profileApi.get({ id: owner, fields: [ 'name', 'email' ] }).then((p) => {
          userCache[owner].resolved = p;
          userCache[owner].cbs.forEach((r) => r(p));
        })
      } else if (userCache[owner].resolved) {
        resolve(userCache[owner].resolved!);
      } else {
        userCache[owner].cbs.push(resolve);
      }
    });
  };

  const searchConfig: SearchEntry[] = [
    {
      id: 'user',
      label: "User",
      operations: [
        { label: "is", value: Operation.Equals },
        { label: "is not", value: Operation.NotEquals },
      ],
      value: 'owner',
      values: {
        fields: [ 'name', 'email' ],
        query: {
          context: { token: '8fb4fa31-1a78-4d25-82dc-a9dda48fa7d8' },
          definition: Apis.Vivi.Profile,
        },
        transform: (profile) => ({
          label: `${(profile as Profile).name} (${(profile as Profile).email})`,
          value: (profile as Profile).id
        })
      }
    },
    {
      id: 'source',
      label: "Source",
      operations: [
        { label: "is", value: Operation.Equals },
        { label: "is not", value: Operation.NotEquals },
      ],
      value: 'source',
      values: Object.keys(Source).map((v) => ({ label: v, value: v }))
    },
  ];

  return (
    <Stack spacing={"1em"}>
      <ApizedListPage<Journey>
        columns={[
          {
            label: 'Owner',
            minWidth: 200,
            format: (value) =>
              <Async promiseFn={resolveProfile} owner={value.owner!}>
                {({ data }) => data ? `${data?.name} (${data.email})` :
                  <div className={"shimmer"} style={{ width: '100%', height: '1.25em' }}/>}
              </Async>
          },
          { label: 'From', minWidth: 165, format: (value) => (value.from as Location)?.name },
          { label: 'To', minWidth: 165, format: (value) => (value.to as Location)?.name },
          { label: 'Mode', sort: "mode", minWidth: 60, format: (value) => value.mode },
          {
            label: 'When',
            sort: "date",
            minWidth: 100,
            format: (value) => toDateTimeString(convertTimeZone({ date: new Date(value.date!), to: "Europe/London" }))
          },
          { label: 'Source', minWidth: 70, format: (value) => value.source },
        ]}
        fields={[ '*', 'from.*', 'to.*' ]}
        context={{ token: '8fb4fa31-1a78-4d25-82dc-a9dda48fa7d8' }}
        query={Apis.Vivi.Journey}
        onRowClick={(collection) => navigate(`/vivi/journeys/${collection.id}`)}
        searchConfig={searchConfig}
      />
    </Stack>
  );
};

export default JourneysPage;
