import React from 'react';
import { Stack } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { SearchEntry } from "../../../components/search/Types";
import { Operation } from "../../../api/Search";
import { Apis } from "../../../api/Config";
import ApizedListPage from "../../../components/ApizedListPage";
import MessageForm from "../components/MessageForm";
import { Message, MessageType } from "../../../api/models/Notification";

const MessagesPage = () => {
  const navigate = useNavigate();

  const { application } = useParams<any>();

  const searchConfig: SearchEntry[] = [
    {
      id: 'address',
      label: "Address",
      operations: [
        { label: "contains", value: Operation.Like },
        { label: "is", value: Operation.Equals },
        { label: "is not", value: Operation.NotEquals },
      ],
      value: 'user',
      values: {
        label: 'user',
        value: 'user',
        query: {
          context: { application },
          definition: Apis.Notification.Message
        },
      }
    },
    {
      id: 'type',
      label: "Type",
      value: "type",
      operations: [
        { label: "is", value: Operation.Equals },
        { label: "is not", value: Operation.NotEquals },
      ],
      values: Object.keys(MessageType).map((k) => ({ label: k, value: k }))
    },
    {
      id: 'title',
      label: "Title",
      value: "title",
      operations: [
        { label: "contains", value: Operation.Like },
        { label: "is", value: Operation.Equals },
        { label: "is not", value: Operation.NotEquals },
      ],
      values: {
        create: true,
        label: 'title',
        value: 'title',
        query: {
          context: { application },
          definition: Apis.Notification.Message
        },
      }
    },
    {
      id: 'body',
      label: "Body",
      value: "body",
      operations: [
        { label: "contains", value: Operation.Like },
        { label: "is", value: Operation.Equals },
        { label: "is not", value: Operation.NotEquals },
      ],
      values: {
        create: true,
        label: 'body',
        value: 'body',
        query: {
          context: { application },
          definition: Apis.Notification.Message
        },
      }
    }
  ];

  return (
    <Stack spacing={"1em"}>
      <ApizedListPage<Message>
        columns={[
          { label: 'To', minWidth: 170, format: (value) => value.user },
          { label: 'Type', minWidth: 50, format: (value) => value.type },
          { label: 'Title', minWidth: 170, format: (value) => value.title },
          { label: 'Body', minWidth: 170, format: (value) => value.body },
          { label: 'Data', minWidth: 170, format: (value) => JSON.stringify(value.data) },
        ]}
        form={MessageForm}
        fields={[ '*', 'template.name' ]}
        context={{ application }}
        query={Apis.Notification.Message}
        onRowClick={(message) => navigate(`/notification/applications/${application}/messages/${message.id}`)}
        searchConfig={searchConfig}
      />
    </Stack>
  );
};

export default MessagesPage;
