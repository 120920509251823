import React from 'react';
import { Breadcrumbs, Chip, IconButton, Stack, Typography } from "@mui/material";
import { SearchEntry } from "../../../components/search/Types";
import { Operation } from "../../../api/Search";
import { Apis } from "../../../api/Config";
import { FeatureFlag, VersionType } from "../../../api/models/Vivi";
import ApizedListPage from "../../../components/ApizedListPage";
import FeatureFlagForm from "../components/FeatureFlagForm";
import { Delete } from "@mui/icons-material";
import apiFor, { ApiError } from "../../../api/Api";
import { useSnackbar } from "notistack";

const FeatureFlagsPage = () => {
  const snackbar = useSnackbar();
  const api = apiFor(Apis.Vivi.FeatureFlag, { token: '8fb4fa31-1a78-4d25-82dc-a9dda48fa7d8' });
  const [ refetchTriger, setRefreshTrigger ] = React.useState(new Date().getTime());

  const searchConfig: SearchEntry[] = [
    {
      id: 'type',
      label: "Type",
      operations: [
        { label: "is", value: Operation.Equals },
        { label: "is not", value: Operation.NotEquals },
      ],
      value: 'id',
      values: Object.keys(VersionType).map((o) => ({ label: o, value: o }))
    },
  ];

  return (
    <Stack spacing={"1em"}>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary">Feature Flags</Typography>
      </Breadcrumbs>
      <ApizedListPage<FeatureFlag>
        columns={[
          { label: 'Name', minWidth: 150, format: (value) => value.name },
          { label: 'Description', minWidth: 350, format: (value) => value.description },
          {
            label: 'Status',
            minWidth: 150,
            format: (value) => value.active
              ? <Chip color={"success"} label="ACTIVE"/>
              : <Chip color={"default"} label="DISABLED"/>
          },
          {
            label: '',
            minWidth: 10,
            format: (target) => <IconButton
              sx={{ padding: 0 }}
              onClick={(e) => {
                e.stopPropagation();
                api.remove({ id: target.id! })
                  .then(() => {
                    snackbar.enqueueSnackbar(`Feature Flag ${target.name} deleted.`, { variant: "success" })
                    setRefreshTrigger(new Date().getTime())
                  })
                  .catch((e: ApiError) => {
                    e.errors.map((error) => snackbar.enqueueSnackbar(error.message, { variant: "error" }))
                  });
              }}
            >
              <Delete/>
            </IconButton>
          }
        ]}
        fields={[ '*', 'from.*', 'to.*' ]}
        form={FeatureFlagForm}
        context={{ token: '8fb4fa31-1a78-4d25-82dc-a9dda48fa7d8' }}
        query={Apis.Vivi.FeatureFlag}
        searchConfig={searchConfig}
        refetchTrigger={refetchTriger}
      />
    </Stack>
  );
};

export default FeatureFlagsPage;
