import React, { useMemo, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Breadcrumbs,
  Button,
  capitalize,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Input,
  Link,
  Stack,
  Switch,
  Typography
} from "@mui/material";
import { useApiGet, useApiList } from "../../../api/ApiHooks";
import { Apis } from "../../../api/Config";
import {
  Favourite,
  FeatureFlag,
  Location,
  Order,
  OrderItem,
  Profile,
  Route,
  Schedule,
  Ticket,
  TravelDirection,
  Trip
} from "../../../api/models/Vivi";
import BorderedSection from "../../../atoms/BorderedSection";
import ProfileForm from "../components/ProfileForm";
import { ArrowForward, Delete } from "@mui/icons-material";
import WeekDay from "../components/WeekDay";
import apiFor, { Service } from "../../../api/Api";
import { convertTimeZone, toDateTimeString, toISOString } from "../../../lib/DateTime";
import { grey } from "@mui/material/colors";
import ApizedAudit from "../../../components/audit/ApizedAudit";
import Loader from "../../../atoms/Loader";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import styled from "styled-components";

const HighlightingGrid = styled(Grid)`
  border-radius: 0.25em;

  &:hover {
    background-color: ${grey[400]};
  }
`;

type ProfileFeatureFlag = {
  system: boolean
} & FeatureFlag;

const ProfilePage = () => {
  const { id } = useParams<any>();
  const navigate = useNavigate();
  const [ useLocationForSuggestions, setUseLocationForSuggestions ] = useState(false)
  const [ suggestionLatLon, setSuggestionLatLon ] = useState<{ lat: number, lon: number }>()
  const [ useTimeForSuggestions, setUseTimeForSuggestions ] = useState(false)
  const [ suggestionTime, setSuggestionTime ] = useState<Date>(new Date())
  const [ useThresholdForSuggestions, setUseThresholdForSuggestions ] = useState(false)
  const [ suggestionThreshold, setSuggestionThreshold ] = useState<number>(0)
  const [ resetting, setResetting ] = useState(false)
  const [ suggestions, setSuggestions ] = useState<Trip[]>()
  const [ suggestionsLoading, setSuggestionsLoding ] = useState(false)
  const [ highlightedSchedule, setHighlightedSchedule ] = useState<string>()

  useMemo(() => {
    apiFor(Apis.Vivi.Info).get({
      id: undefined,
      fields: [ 'suggestionThreshold' ]
    })
      .then((i) => setSuggestionThreshold(i.suggestionThreshold || 0))
  }, []);

  const { data: profile, loading, refetch } = useApiGet<Profile>(
    Apis.Vivi.Profile,
    { token: '8fb4fa31-1a78-4d25-82dc-a9dda48fa7d8' },
    {
      id
    }
  )

  const { data: features } = useApiList(
    Apis.Vivi.FeatureFlag,
    { token: '8fb4fa31-1a78-4d25-82dc-a9dda48fa7d8' },
    {
      fields: [ 'name', 'description', 'active' ],
      pageSize: 999
    }
  );

  const { data: routes } = useApiList<Route>(
    Apis.Vivi.Route,
    { token: id },
    {
      pageSize: 100,
      fields: [ 'owner', 'from.name', 'to.name', 'schedules.direction', 'schedules.mode', 'schedules.time', 'schedules.values' ]
    }
  )

  const { data: favourites } = useApiList<Favourite>(
    Apis.Vivi.Favourite,
    { token: id },
    {
      pageSize: 100,
      fields: [ 'name', 'address', 'value' ]
    }
  )

  const profileApi = apiFor(Apis.Vivi.Profile, { token: '8fb4fa31-1a78-4d25-82dc-a9dda48fa7d8' });
  const suggestionApi = apiFor(Apis.Vivi.Suggestion, { token: id });

  const fetchSuggestions = () => {
    const params: { location?: string, timeOfRating?: string, threshold?: string } = {};
    if (useLocationForSuggestions) {
      params.location = `${suggestionLatLon?.lat}, ${suggestionLatLon?.lon}`;
    }
    if (useTimeForSuggestions) {
      params.timeOfRating = toISOString(convertTimeZone({
          date: suggestionTime,
          to: 'UTC'
        })
      );
    }
    if (useThresholdForSuggestions) {
      params.threshold = '' + suggestionThreshold;
    }
    console.log(suggestionTime);
    setSuggestionsLoding(true);
    suggestionApi.list({
      params,
      fields: [ 'source', 'mode', 'depart', 'journeyConfidence', 'metadata', 'from.name', 'to.name', 'orders.status', 'orders.orderItems.tickets.reference' ]
    }).then((r) => {
      setSuggestions(r.content);
      setSuggestionsLoding(false);
    });
  }

  const featureFlags: ProfileFeatureFlag[] = features?.content?.map(value => ({
    name: value.name,
    description: value.description,
    active: profile?.featureFlags?.[value.name!] as boolean | undefined,
    system: value.active
  } as ProfileFeatureFlag));

  return (
    <Stack spacing={"1em"}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="#/vivi/profiles">
            Profiles
          </Link>
          <Typography color="text.primary">Profile <b>{profile?.id}</b></Typography>
        </Breadcrumbs>
        <Stack direction={"row"} spacing={"1em"}>
          <Button disabled={resetting} variant={"contained"} color={"error"} onClick={() => {
            if (window.confirm("Are you sure you wish to reset this user?")) {
              setResetting(true);
              profileApi.remove({ id: id! })
                .then(() => window.location.reload());
            }
          }}>
            Reset {resetting && <Loader/>}
          </Button>
          <Button variant={"contained"} onClick={() => navigate(`/vivi/journeys?q=user%3D${id!}`)}>Journeys</Button>
          <Button variant={"contained"} onClick={() => navigate(`/vivi/trips?q=user%3D${id!}`)}>Trips</Button>
          <Button variant={"contained"} onClick={() => navigate(`/vivi/orders?q=email%3D${id!}`)}>Orders</Button>
          <Button variant={"contained"} onClick={() => navigate(`/vivi/tickets?q=email%3D${id!}`)}>Tickets</Button>
          <ApizedAudit service={Service.Vivi} entity={"users"} target={id}/>
        </Stack>

      </Stack>
      {!loading && profile && (
        <>
          <BorderedSection title={"Details"}>
            <ProfileForm selected={profile}/>
          </BorderedSection>
          <BorderedSection title={"Feature Flags"}>
            {features && profile && featureFlags.map((feature) => (
              <Stack key={feature.id} direction={"row"} alignItems={"center"} spacing={"1em"}>
                <FormGroup style={{ alignItems: "flex-start" }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={typeof feature.active !== 'undefined' ? feature.active : feature.system}
                        onChange={(a) => {
                          console.log("checked", a.target.checked);
                          profileApi.update({
                            id: id!,
                            obj: {
                              featureFlags: {
                                ...profile.featureFlags,
                                [feature.name!]: a.target.checked
                              }
                            }
                          }).then(refetch);
                        }}
                      />
                    }
                    label={
                      <Stack direction={"row"} alignItems={"center"} spacing={"1em"}>
                        <Box>{feature.name}</Box>
                        {typeof feature.active !== 'undefined' &&
                          <IconButton
                            onClick={() =>
                              profileApi.update({
                                id: id!,
                                obj: {
                                  featureFlags: {
                                    ...profile.featureFlags,
                                    [feature.name!]: undefined
                                  }
                                }
                              }).then(refetch)
                            }
                          >
                            <Delete/>
                          </IconButton>
                        }
                      </Stack>
                    }
                    labelPlacement="start"
                  />
                </FormGroup>
                <Box>
                  <Typography
                    color={grey[600]}
                  >
                    {feature.description}
                  </Typography>
                </Box>
              </Stack>
            ))}
          </BorderedSection>
          <BorderedSection title={"Favourites"}>
            <Grid container spacing={2} margin={0}>
              <Grid item xs={2}><Box><b>Name</b></Box></Grid>
              <Grid item xs={4}><Box><b>Lat/Lon</b></Box></Grid>
              <Grid item xs={6}><Box><b>Address</b></Box></Grid>
            </Grid>
            {favourites && favourites?.content.map((fav) => (
              <Grid key={fav.id} container spacing={2} margin={0}>
                <Grid item xs={2}><Box>{fav.name}</Box></Grid>
                <Grid item xs={4}><Box>{fav.value}</Box></Grid>
                <Grid item xs={6}><Box>{fav.address}</Box></Grid>
              </Grid>
            ))}
          </BorderedSection>
          <BorderedSection title={"Routes"}>
            {routes &&
              <Stack spacing={"1em"}>
                {routes.content.map((route) => {
                  const outbound = (route.schedules as Schedule[])?.find((s) => s.direction === TravelDirection.OUTBOUND);
                  const inbound = (route.schedules as Schedule[])?.find((s) => s.direction === TravelDirection.INBOUND);
                  return (
                    <Stack key={route.id} direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                      <Stack direction={"row"} spacing={"1em"} alignItems={"center"}>
                        <Stack direction={"row"} spacing={"0.5em"} alignItems={"center"}
                               style={{
                                 padding: "0.25em",
                                 borderRadius: "0.25em",
                                 backgroundColor: highlightedSchedule === outbound?.id ? grey[400] : grey[100]
                               }}>
                          <Box>{outbound?.id?.substring(24)}:</Box>
                          <Stack direction={"row"} alignItems={"center"}>
                            <span>{(route.from as Location).name}</span>
                            <ArrowForward/>
                            <span>{(route.to as Location).name}</span>
                          </Stack>
                          <Stack direction={"row"} alignItems={"center"} spacing={"0.25em"}>
                            <span>{capitalize(outbound?.mode?.toLowerCase() || '')}</span>
                            <span>{outbound?.time}</span>
                          </Stack>
                        </Stack>
                        <Stack direction={"row"} spacing={"0.5em"} alignItems={"center"}
                               style={{
                                 padding: "0.25em",
                                 borderRadius: "0.25em",
                                 backgroundColor: highlightedSchedule === inbound?.id ? grey[400] : grey[100]
                               }}>
                          <Box>{inbound?.id?.substring(24)}:</Box>
                          <Stack direction={"row"} alignItems={"center"}>
                            <span>{(route.to as Location).name}</span>
                            <ArrowForward/>
                            <span>{(route.from as Location).name}</span>
                          </Stack>
                          <Stack direction={"row"} alignItems={"center"} spacing={"0.25em"}>
                            <span>{capitalize(inbound?.mode?.toLowerCase() || '')}</span>
                            <span>{inbound?.time}</span>
                          </Stack>
                        </Stack>
                        <Stack direction={"row"} spacing={"1em"}>
                          <Stack direction={"row"} spacing={"0.25em"}>
                            <WeekDay
                              className={(route.schedules as Schedule[])[0].values?.find((v) => v === 1) ? "active" : ""}><span>M</span></WeekDay>
                            <WeekDay
                              className={(route.schedules as Schedule[])[0].values?.find((v) => v === 2) ? "active" : ""}><span>T</span></WeekDay>
                            <WeekDay
                              className={(route.schedules as Schedule[])[0].values?.find((v) => v === 3) ? "active" : ""}><span>W</span></WeekDay>
                            <WeekDay
                              className={(route.schedules as Schedule[])[0].values?.find((v) => v === 4) ? "active" : ""}><span>T</span></WeekDay>
                            <WeekDay
                              className={(route.schedules as Schedule[])[0].values?.find((v) => v === 5) ? "active" : ""}><span>F</span></WeekDay>
                            <WeekDay
                              className={(route.schedules as Schedule[])[0].values?.find((v) => v === 6) ? "active" : ""}><span>S</span></WeekDay>
                            <WeekDay
                              className={(route.schedules as Schedule[])[0].values?.find((v) => v === 7) ? "active" : ""}><span>S</span></WeekDay>
                          </Stack>
                        </Stack>
                      </Stack>
                      <Link underline="hover" target="_blank" href={`#/vivi/schedules/${route.id}`}>
                        View schedule
                      </Link>
                    </Stack>);
                })}
              </Stack>
            }
          </BorderedSection>
          <BorderedSection title={"Suggestions"}>
            <Stack spacing={"1em"}>
              <Stack direction={"row"} spacing={"1em"} alignItems={"center"}>
                <FormGroup style={{ alignItems: "flex-start" }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={useLocationForSuggestions}
                        onChange={(e) => {
                          setUseLocationForSuggestions(e.target.checked);
                          navigator.geolocation.getCurrentPosition((v) => {
                            setSuggestionLatLon({
                              lat: v.coords.latitude,
                              lon: v.coords.longitude
                            })
                          }, () => {
                            setSuggestionLatLon({
                              lat: 51.50853,
                              lon: -0.12574
                            });
                          });
                        }}
                      />
                    }
                    label={"Location"}
                    labelPlacement="start"
                  />
                </FormGroup>
                <Input
                  disabled={!useLocationForSuggestions || typeof suggestionLatLon?.lat === 'undefined'}
                  placeholder="Latitude"
                  value={useLocationForSuggestions ? suggestionLatLon?.lat : undefined}
                  onChange={(e) => setSuggestionLatLon({ lon: 0, ...suggestionLatLon, lat: +e.target.value })}
                />
                <Input
                  disabled={!useLocationForSuggestions || typeof suggestionLatLon?.lon === 'undefined'}
                  placeholder="Longitude"
                  value={useLocationForSuggestions ? suggestionLatLon?.lon : undefined}
                  onChange={(e) => setSuggestionLatLon({ lat: 0, ...suggestionLatLon, lon: +e.target.value })}
                />
                <FormGroup style={{ alignItems: "flex-start" }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={useTimeForSuggestions}
                        onChange={(e) => {
                          setUseTimeForSuggestions(e.target.checked);
                        }}
                      />
                    }
                    label={"Time Of Rating"}
                    labelPlacement="start"
                  />
                </FormGroup>
                <DateTimePicker
                  ampm={false}
                  format="YYYY-MM-DD HH:mm"
                  disabled={!useTimeForSuggestions}
                  label="Date/Time"
                  value={dayjs(suggestionTime)}
                  onChange={(d) => {
                    try {
                      const date = (d as unknown as Dayjs).toDate();
                      if (!isNaN(date.getTime())) {
                        setSuggestionTime(date);
                      }
                    } catch (e) {
                      //Do nothing
                    }
                  }}
                />
                <FormGroup style={{ alignItems: "flex-start" }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={useThresholdForSuggestions}
                        onChange={(e) => {
                          setUseThresholdForSuggestions(e.target.checked);
                        }}
                      />
                    }
                    label={"Threshold"}
                    labelPlacement="start"
                  />
                </FormGroup>
                <Input
                  disabled={!useThresholdForSuggestions}
                  placeholder="Threshold"
                  value={suggestionThreshold}
                  onChange={(e) => setSuggestionThreshold(+e.target.value)}
                />
              </Stack>
              <Button variant={"contained"} disabled={suggestionsLoading} onClick={fetchSuggestions}>
                Fetch Suggestions
              </Button>
              {suggestionsLoading &&
                <Stack spacing={"1em"}>
                  <div className={"shimmer"} style={{ width: '100%', height: '1.5em' }}/>
                  <div className={"shimmer"} style={{ width: '100%', height: '1.5em' }}/>
                  <div className={"shimmer"} style={{ width: '100%', height: '1.5em' }}/>
                </Stack>
              }
              {!suggestionsLoading && <Box>
                <Grid container spacing={0} margin={0} padding={1}>
                  <Grid item xs={3} padding={"0 1em"}><Box><b>Route</b></Box></Grid>
                  <Grid item xs={3} padding={"0 1em"}><Box><b>Date</b></Box></Grid>
                  <Grid item xs={1} padding={"0 1em"}><Box><b>Confidence</b></Box></Grid>
                  <Grid item xs={2} padding={"0 1em"}><Box><b>Source</b></Box></Grid>
                  <Grid item xs={2} padding={"0 1em"}><Box><b>Ticket</b></Box></Grid>
                  <Grid item xs={1} padding={"0 1em"}><Box></Box></Grid>
                </Grid>
                {suggestions?.map((trip) => {
                  const ticket = (((trip.orders as Order[])?.find((o) => o.status === 'COMPLETE')?.orderItems as OrderItem[])?.[0]?.tickets as Ticket[])?.[0];
                  return (
                    <HighlightingGrid
                      container
                      spacing={0}
                      margin={0}
                      padding={1}
                      alignItems={"center"}
                      onMouseEnter={() => setHighlightedSchedule(trip.metadata?.scheduleId as string)}
                      onMouseLeave={() => setHighlightedSchedule(undefined)}
                    >
                      <Grid item xs={3} padding={"0 1em"}>
                        <Stack direction={"row"} alignItems={"center"}>
                          <span>{(trip.from as Location).name}</span>
                          <ArrowForward/>
                          <span>{(trip.to as Location).name}</span>
                        </Stack>
                      </Grid>
                      <Grid item xs={3} padding={"0 1em"}>
                        {`${capitalize(trip.mode?.toLowerCase() || '')} at ${toDateTimeString(convertTimeZone({
                          date: new Date(trip.depart!),
                          from: 'UTC'
                        }))}`}
                      </Grid>
                      <Grid item xs={1} padding={"0 1em"}>
                        {Math.round(trip.journeyConfidence! * 10000) / 100}%
                      </Grid>
                      <Grid item xs={2} padding={"0 1em"}>
                        {trip.source}
                      </Grid>
                      <Grid item xs={2} padding={"0 1em"}>
                        <Box>
                          {ticket && (
                            <Stack direction={"row"} spacing={"0.25em"}>
                              <span>Ticket:</span>
                              <Link underline="hover" target="_blank" href={`#/vivi/tickets/${ticket.id}`}>
                                {ticket?.reference}
                              </Link>
                            </Stack>
                          )}
                        </Box>
                      </Grid>
                      <Grid item xs={1} padding={"0 1em"}>
                        <Link underline="hover" target="_blank" href={`#/vivi/trips/${trip.id}`}>
                          View trip
                        </Link>
                      </Grid>
                    </HighlightingGrid>
                  );
                })}
              </Box>
              }
            </Stack>
          </BorderedSection>
        </>
      )}
    </Stack>
  );
};

export default ProfilePage;
