/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { useApiGet } from "../../../api/ApiHooks";
import { Apis } from "../../../api/Config";
import { Execution, Message, Target } from "../../../api/models/Webhooks";
import { Box, Breadcrumbs, Button, Link, Stack, TextField, Typography } from "@mui/material";
import BorderedSection from "../../../atoms/BorderedSection";
import Loader from "../../../atoms/Loader";
import MessageForm from "../components/MessageForm";
import TargetForm from "../components/TargetForm";
import ModelTable, { Column } from "../components/Table";
import { GreenBadge, GreyBadge, RedBadge, YellowBadge } from "../../../atoms/Badge";
import { convertTimeZone, toDateTimeString } from "../../../lib/DateTime";
import apiFor from "../../../api/Api";

const tableShape: Column<Execution>[] = [
  {
    label: 'Date',
    sort: 'createdAt',
    minWidth: 120,
    format: (value) => toDateTimeString(convertTimeZone({ date: new Date(value.createdAt!), from: 'UTC', to: '' }))
  },
  {
    label: 'Status',
    minWidth: 100,
    format: (item): JSX.Element => {
      switch (Math.round((item.status || 1) / 100)) {
        case 2:
          return <GreenBadge>{item.status}</GreenBadge>;
        case 4:
          return <YellowBadge>{item.status}</YellowBadge>;
        case 5:
          return <RedBadge>{item.status}</RedBadge>;
        default:
          return <GreyBadge>{item.status}</GreyBadge>;
      }
    },
  },
  {
    label: 'Response',
    minWidth: 400,
    format: (item) => item.response,
  },
];

const ExecutionPage = (): ReactElement => {
  const { id } = useParams<{ id: string }>();
  const { loading, data: execution, refetch } = useApiGet(
    Apis.Webhooks.Execution,
    {},
    {
      id,
      fields: [ '*', 'message.topic', 'message.payload', 'target.*', 'retries.*' ],
    }
  );

  const executionApi = apiFor(Apis.Webhooks.Execution);

  const attempts = (
    [ execution ].concat(
      execution?.retries as Execution[],
    ) as Execution[]
  )
    .sort((a, b) => (a.createdAt || '').localeCompare(b?.createdAt || ''))
    .reverse();

  const retriesAllowed =
    Math.round((execution?.latestStatus || 200) / 100) !== 2;

  const inProgress = !loading && execution?.latestStatus === 0;
  if (inProgress) {
    setTimeout(() => refetch(), 200);
  }

  let latestStatusBadge;
  switch (Math.round((execution?.latestStatus || 1) / 100)) {
    case 2:
      latestStatusBadge = <GreenBadge style={{ display: "inline-block" }}>{execution?.latestStatus}</GreenBadge>;
      break;
    case 4:
      latestStatusBadge = <YellowBadge style={{ display: "inline-block" }}>{execution?.latestStatus}</YellowBadge>;
      break;
    case 5:
      latestStatusBadge = <RedBadge style={{ display: "inline-block" }}>{execution?.latestStatus}</RedBadge>;
      break;
    default:
      latestStatusBadge = <GreyBadge style={{ display: "inline-block" }}>{execution?.latestStatus}</GreyBadge>;
      break;
  }

  return (
    <Stack spacing={"1em"}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="#/webhooks/executions">
            Executions
          </Link>
          <Typography color="text.primary">Execution <b>{execution?.id}</b></Typography>
        </Breadcrumbs>
        <Stack direction={"row"} spacing={"1em"}>
          <Button
            style={{ width: "10em" }}
            variant={"contained"}
            disabled={!retriesAllowed || inProgress}
            onClick={() => executionApi.remove({ id: execution.id! }).then(refetch)}
          >
            Retry
          </Button>
          <Button
            style={{ width: "10em" }}
            variant={"contained"}
            disabled={inProgress}
            onClick={() => refetch()}
          >
            {inProgress ? <Loader/> : 'Reload'}
          </Button>
        </Stack>
      </Stack>
      {!loading && execution && (
        <Stack direction="row" spacing={"2em"} alignItems="top" justifyContent="space-between">
          <Box width={"40em"} >
            <Stack spacing="1em">
              <BorderedSection title="Target">
                <TargetForm
                  isModal={false}
                  selected={execution?.target as Target}
                />
              </BorderedSection>
              <BorderedSection title="Message">
                <MessageForm
                  isModal={false}
                  selected={execution?.message as Message}
                />
              </BorderedSection>
            </Stack>
          </Box>
          <Box flexGrow={1}>
            <Stack spacing="1em">
              <BorderedSection title="Details">
                <Stack spacing={"2em"}>
                  <Stack spacing="1em">
                    <label htmlFor="status">Status</label>
                    <Box id="status">
                      {latestStatusBadge}
                    </Box>
                  </Stack>
                  <Stack spacing="1em">
                    <label htmlFor="Response">Response</label>
                    <TextField
                      disabled
                      name={"response"}
                      multiline
                      minRows={5}
                      maxRows={20}
                      value={execution.latestResponse}
                    />
                  </Stack>
                </Stack>
              </BorderedSection>
              <BorderedSection title="Attempts">
                <ModelTable<Execution>
                  columns={tableShape}
                  entries={attempts}
                  loading={loading}
                />
              </BorderedSection>
            </Stack>
          </Box>
        </Stack>
      )}
    </Stack>
  );
};

export default ExecutionPage;
