import { Button, Stack } from "@mui/material";
import React from "react";
import apiFor, { ApiError } from "../../../api/Api";
import { Apis } from "../../../api/Config";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import FormikField from "../../../components/FormikField";
import { ApizedFormProps } from "../../../components/ApizedListPage";
import { useParams } from "react-router-dom";
import { Message, MessageType } from "../../../api/models/Notification";

const MessageForm = (
  {
    isModal,
    onClose = () => null,
    selected
  }: ApizedFormProps<Message>
) => {
  const { enqueueSnackbar } = useSnackbar();
  const { application } = useParams();
  const api = apiFor(Apis.Notification.Message, { application });
  const formik = useFormik({
    initialValues: {
      id: selected?.id || undefined,
      user: selected?.user || '',
      type: selected?.type || MessageType.ALERT,
      title: selected?.title || '',
      body: selected?.body || '',
      data: JSON.stringify(selected?.data || {}),
    },
    validationSchema: Yup.object().shape({
      user: Yup.string().required(),
      type: Yup.string().required(),
      title: Yup.string().test({
        exclusive: false,
        test: (_value, validationContext) => {
          const parent = validationContext.parent
          if (parent?.type === MessageType.ALERT && !_value) {
            return validationContext?.createError({
              message: "Title is required",
              path: validationContext?.path,
            })
          }
          return true
        }
      }),
      body: Yup.string().test({
        exclusive: false,
        test: (_value, validationContext) => {
          const parent = validationContext.parent
          if (parent?.type === MessageType.ALERT && !_value) {
            return validationContext?.createError({
              message: "Body is required",
              path: validationContext?.path,
            })
          }
          return true
        }
      }),
      data: Yup.string().test({
        exclusive: false,
        test: (_value, validationContext) => {
          try {
            if (typeof JSON.parse(_value || '') !== 'object') {
              return validationContext?.createError({
                message: "Data must be an object",
                path: validationContext?.path,
              });
            }
          } catch (e) {
            return validationContext?.createError({
              message: "Data must be a valid json",
              path: validationContext?.path,
            })
          }
          return true;
        }
      })
    }),
    onSubmit: (values) => {
      if (!selected) {
        api.create({
          obj: { ...values, data: JSON.parse(values.data) },
        }).then((u) => {
          onClose(u);
          enqueueSnackbar(`Message '${u.id}' created!`, { variant: "success" });
        }).catch((e: ApiError) => {
          e.errors.map(error => enqueueSnackbar(error.message, { variant: "error" }))
        })
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={"1em"} bottom={"1em"}>
        <FormikField
          required
          disabled={!!selected?.id}
          formik={formik}
          label={"User"}
          field={"user"}
          type={"text"}
        />
        <FormikField
          required
          disabled={!!selected}
          formik={formik}
          label={"Type"}
          field={"type"}
          type={"select"}
          options={Object.keys(MessageType).map((o) => ({ label: o, value: o }))}
        />
        {formik.values.type === MessageType.ALERT && (
          <>
            <FormikField
              required={formik.values.type === MessageType.ALERT}
              disabled={!!selected?.id}
              formik={formik}
              label={"Title"}
              field={"title"}
              type={"text"}
            />
            <FormikField
              required={formik.values.type === MessageType.ALERT}
              disabled={!!selected?.id}
              formik={formik}
              label={"Body"}
              field={"body"}
              type={"text"}
            />
          </>
        )}
        <FormikField
          required
          multiline
          minRows={3}
          maxRows={6}
          disabled={!!selected?.id}
          formik={formik}
          label={"Data"}
          field={"data"}
          type={"text"}
        />
        <Stack direction={"row"} spacing={"1em"} justifyContent={"right"}>
          {isModal && (<Button variant={"outlined"} onClick={() => onClose()}>Cancel</Button>)}
          {!selected && <Button variant={"contained"} onClick={formik.submitForm}>Create</Button>}
        </Stack>
      </Stack>
    </form>
  );
};

export default MessageForm;